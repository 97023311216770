import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import {
  get,
  update,
  createPayment,
  updatePayment,
  deletePayment,
  createExpense,
  updateExpense,
  deleteExpense,
} from 'services/invoices';
import Breadcrumbs from 'components/navbar/Breadcrumbs';
import { formatCurrency } from 'helpers/currency';
import { FaExternalLinkAlt } from 'react-icons/fa';
import {
  FaCcStripe,
  FaEllipsisVertical,
  FaEnvelope,
  FaPencil,
  FaPlus,
  FaPrint,
  FaTrashCan,
} from 'react-icons/fa6';
import ModalBillingAddress from './components/ModalBillingAddress';
import ModalShippingAddress from './components/ModalShippingAddress';
import ModalPremium from './components/ModalPremium';
import ModalTax from './components/ModalTax';
import ModalExpenses from './components/ModalExpenses';
import AddPaymentModal from './components/AddPaymentModal';
import Button from 'components/buttons/Button';
import SplitButton from 'components/buttons/SplitButton';
import { useEmailDispatch } from 'components/email/EmailContext';
import { can, useAuth } from 'components/auth/AuthContext';
import { displayDatetime } from 'helpers/dates';
import logo from 'assets/img/peak/logo.svg';
import NoSaleLotModal from './components/NoSaleLotModal'
import ResellLotModal from './components/ResellLotModal'
import { Menu } from 'primereact/menu'
import { Button as PrimeButton } from 'primereact/button'

const Edit = () => {
  const navigate = useNavigate();
  const emailDispatch = useEmailDispatch();
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);

  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [showTaxModal, setShowTaxModal] = useState(false);
  const [showExpensesModal, setShowExpensesModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showNoSaleModal, setShowNoSaleModal] = useState(false);
  const [showResellLotModal, setShowResellLotModal] = useState(false);

  const [modalFieldValues, setModalFieldValues] = useState({});
  const [activeInvoiceItem, setActiveInvoiceItem] = useState(null);

  const auth = useAuth();
  const canEdit = can(auth, 'update_invoices');
  const canAccessStripe = can(auth, 'access_stripe');

  const InvoiceItemMenu = ({ item }) => {
    const menu = useRef(null);
    const items = [
      {
        label: 'Sell to another bidder',
        command: () => {
          setActiveInvoiceItem(item)
          setShowResellLotModal(true)
        }
      }
    ]

    if (! item.lot?.no_sale) {
      items.push({
        label: 'Mark as No Sale',
        command: () => {
          setActiveInvoiceItem(item)
          setShowNoSaleModal(true)
        }
      })
    }

    return (
        <>
          <Menu model={items} popup ref={menu} id="popup_menu_right" popupAlignment="right" />
          <PrimeButton text icon={(
              <FaEllipsisVertical className="w-5 h-5" />
          )} className="mr-2" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
        </>
    )
  }

  const loadInvoice = () => {
    get(id, ({ data }) => {
      setInvoice(data.invoice);
    });
  };

  useEffect(() => {
    loadInvoice();
  }, []);

  if (!invoice) {
    return;
  }

  const billingAddress = invoice.addresses.filter(
    (address) => address.type === 'Billing'
  )[0];
  const shippingAddress = invoice.addresses.filter(
    (address) => address.type === 'Shipping'
  )[0];

  const totals = {
    qty: 0,
    bid: 0,
    premium: 0,
    tax: 0,
    expenses: 0,
    total: 0,
  };

  const handleEditClick = (fieldValues, modal) => {
    setModalFieldValues(fieldValues);

    if (modal === 'billing') {
      setShowBillingModal(true);
      return;
    }

    if (modal === 'shipping') {
      setShowShippingModal(true);
      return;
    }

    if (modal === 'premium') {
      setShowPremiumModal(true);
      return;
    }

    if (modal === 'tax') {
      setShowTaxModal(true);
      return;
    }

    if (modal === 'expenses') {
      setShowExpensesModal(true);
      return;
    }
  };

  const setData = (key, value) => {
    setModalFieldValues({
      ...modalFieldValues,
      [key]: value,
    });
  };

  const handleSave = async (newValues, type) => {
    update(id, { ...newValues, type: type }, ({ data }) => {
      setInvoice(data.invoice);
    });
  };

  const handleSavePremium = async (newValues) => {
    update(id, newValues, ({ data }) => {
      loadInvoice();
    });
  };

  const handleSaveTax = async (newValues) => {
    update(id, newValues, ({ data }) => {
      loadInvoice();
    });
  };

  const handleSaveExpenses = async (newValues) => {
    if (newValues.id) {
      updateExpense(id, newValues.id, newValues, ({ data }) => {
        loadInvoice();
      });
    } else {
      createExpense(id, newValues, ({ data }) => {
        loadInvoice();
      });
    }
  };

  const handleDeleteExpense = (expense_id) => {
    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }

    deleteExpense(id, expense_id, () => {
      loadInvoice();
    });
  };

  const handleSubmitPayment = (id, data, then) => {
    if (data.id) {
      updatePayment(id, data.id, data, (response) => {
        then(response);
        loadInvoice();
      });
    } else {
      createPayment(id, data, (response) => {
        then(response);
        loadInvoice();
      });
    }
  };

  const handleDeletePayment = (payment_id) => {
    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }

    deletePayment(id, payment_id, () => {
      loadInvoice();
    });
  };

  const showRelatedExpenses = (expenses) => {
    return (
      <>
        {expenses.map((expense) => (
          <div
            key={expense.id}
            className="flex items-start justify-between gap-4 pb-2 text-left min-w-48"
          >
            {expense.description && (
              <div className="w-1/2 text-xs leading-loose">{expense.description}</div>
            )}
            <div className="w-1/4 text-right">
              {formatCurrency(expense.calculated_amount)}
            </div>
            {canEdit && (
              <div className="flex items-center gap-2 pt-1">
                <button
                  onClick={() => handleEditClick(expense, 'expenses')}
                  className="text-slate-400 hover:text-slate-600"
                >
                  <FaPencil />
                </button>
                <button
                  onClick={() => handleDeleteExpense(expense.id)}
                  className="text-red-400 hover:text-red-600"
                >
                  <FaTrashCan />
                </button>
              </div>
            )}
          </div>
        ))}

        {expenses.length == 0 && <em>None</em>}
      </>
    );
  };

  const sendEmail = (title, subject, message = '') => {
    emailDispatch({
      type: 'show',
      email: {
        modal: {
          title,
        },
        subject,
        message,
        invoice_id: invoice.id,
      },
    });
  };

  return (
    <div>
      <ModalBillingAddress
        show={showBillingModal}
        onClose={() => setShowBillingModal(false)}
        fieldValues={modalFieldValues}
        onSave={handleSave}
        setData={setData}
      />

      <ModalShippingAddress
        show={showShippingModal}
        onClose={() => setShowShippingModal(false)}
        fieldValues={modalFieldValues}
        onSave={handleSave}
        setData={setData}
      />

      <ModalPremium
        show={showPremiumModal}
        onClose={() => setShowPremiumModal(false)}
        fieldValues={modalFieldValues}
        onSave={handleSavePremium}
        setData={setData}
      />

      <ModalTax
        show={showTaxModal}
        onClose={() => setShowTaxModal(false)}
        fieldValues={modalFieldValues}
        onSave={handleSaveTax}
        setData={setData}
      />

      <ModalExpenses
        show={showExpensesModal}
        onClose={() => setShowExpensesModal(false)}
        fieldValues={modalFieldValues}
        onSave={handleSaveExpenses}
        setData={setData}
      />

      <AddPaymentModal
        invoice={invoice}
        payment={modalFieldValues}
        visible={showPaymentModal}
        setVisible={setShowPaymentModal}
        submit={handleSubmitPayment}
      />

      <NoSaleLotModal
          invoiceItem={activeInvoiceItem}
          visible={showNoSaleModal}
          setVisible={setShowNoSaleModal}
          then={() => {
            setActiveInvoiceItem(null)
            loadInvoice()
          }}
      />

      <ResellLotModal
          auction={invoice.auction}
          invoice={invoice}
          invoiceItem={activeInvoiceItem}
          visible={showResellLotModal}
          setVisible={setShowResellLotModal}
          then={() => {
            setActiveInvoiceItem(null)
            loadInvoice()
          }}
      />

      <div className="fixed z-10 ml-[11px] mt-[-72px]">
        <Breadcrumbs
          trail={[
            {
              href: '/admin/auctions',
              title: 'Auctions',
            },
            {
              href: `/admin/auction/${invoice.auction_id}`,
              title: invoice.auction.name,
            },
            {
              href: `/admin/auction/${invoice.auction_id}/invoices`,
              title: 'Invoices',
            },
            {
              href: '#',
              title: `Invoice #${invoice.number}`,
            },
          ]}
        />
      </div>

      <div className="mt-3 w-full">
        <div className="flex items-center justify-between gap-12 pt-6 px-4">
          <div className="w-32">
            <img src={logo} alt="Peak Auto Auctions" className="w-full" />
          </div>
          <div>
            <div className="flex items-center gap-2 text-lg font-bold">
              Billing Address
              {canEdit && (
                <button
                  onClick={() =>
                    handleEditClick(
                      {
                        first_name: billingAddress?.first_name || '',
                        last_name: billingAddress?.last_name || '',
                        address_line1: billingAddress?.address_line1 || '',
                        address_line2: billingAddress?.address_line2 || '',
                        city: billingAddress?.city || '',
                        state: billingAddress?.state || '',
                        zip: billingAddress?.zip || '',
                      },
                      'billing'
                    )
                  }
                  className="text-slate-400 hover:text-slate-600"
                >
                  <FaPencil className="h-4 w-4" />
                </button>
              )}
              {canEdit && (
                  <Link
                      to={`/admin/user/${invoice.buyer_id}/edit`}
                      className="text-slate-400 hover:text-slate-600"
                  >
                    <FaExternalLinkAlt className="h-4 w-4" />
                  </Link>
              )}
            </div>
            {billingAddress ? (
              <div>
                {billingAddress.first_name} {billingAddress.last_name}
                <br />
                {billingAddress.address_line1}
                <br />
                {billingAddress.address_line2 && (
                  <div>{billingAddress.address_line2}</div>
                )}
                {billingAddress.city}, {billingAddress.state}{' '}
                {billingAddress.zip}
              </div>
            ) : (
              'N/A'
            )}
          </div>
          <div>
            <div className="flex items-center gap-2 text-lg font-bold">
              Shipping Address
              {canEdit && (
                <button
                  onClick={() =>
                    handleEditClick(
                      {
                        first_name: shippingAddress?.first_name || '',
                        last_name: shippingAddress?.last_name || '',
                        address_line1: shippingAddress?.address_line1 || '',
                        address_line2: shippingAddress?.address_line2 || '',
                        city: shippingAddress?.city || '',
                        state: shippingAddress?.state || '',
                        zip: shippingAddress?.zip || '',
                      },
                      'shipping'
                    )
                  }
                  className="text-slate-400 hover:text-slate-600"
                >
                  <FaPencil className="h-4 w-4" />
                </button>
              )}
            </div>
            {shippingAddress ? (
              <div>
                {shippingAddress.first_name} {shippingAddress.last_name}
                <br />
                {shippingAddress.address_line1}
                <br />
                {shippingAddress.address_line2 && (
                  <div>{shippingAddress.address_line2}</div>
                )}
                {shippingAddress.city}, {shippingAddress.state}{' '}
                {shippingAddress.zip}
              </div>
            ) : (
              'N/A'
            )}
          </div>
          <div>{displayDatetime(invoice.created_at)}</div>
          <div
            className={
              'text-2xl font-bold uppercase ' +
              (invoice.status === 'paid' ? 'text-green-600' : 'text-red-600')
            }
          >
            {invoice.status}
          </div>
          <div className="flex items-center gap-2">
            {canEdit && (
              <SplitButton
                items={[
                  {
                    name: 'Email Unpaid',
                    onClick: () =>
                      sendEmail(
                        'Send Unpaid Email Notification to Buyer',
                        'Your invoice is unpaid'
                      ),
                  },
                  {
                    name: 'Email Past Due',
                    onClick: () =>
                      sendEmail(
                        'Send Past Due Notification to Buyer',
                        'Your invoice is past due'
                      ),
                  },
                  {
                    name: 'Email Payment Due',
                    onClick: () =>
                      sendEmail(
                        'Send Payment Due Notification to Buyer',
                        'Your invoice payment is due'
                      ),
                  },
                  {
                    name: 'Email Paid Receipt',
                    onClick: () =>
                      sendEmail(
                        'Send Paid Receipt Notification to Buyer',
                        'Your invoice has been paid',
                        'Your invoice has been paid'
                      ),
                  },
                ]}
                onClick={() =>
                  sendEmail('Email Invoice', 'Your invoice is ready')
                }
              >
                <FaEnvelope className="h-5 w-5" />
              </SplitButton>
            )}

            <Button
              color="outline"
              href={`${process.env.REACT_APP_BASE_URL}/admin/invoices/${invoice.id}/download`}
              target="_blank"
            >
              <FaPrint className="h-5 w-5" />
            </Button>
          </div>
        </div>

        {invoice.auction?.config?.invoice_header && (
            <div className="my-8 text-center w-3/4" dangerouslySetInnerHTML={{
              __html: invoice.auction?.config?.invoice_header
            }} />
        )}

        <table className="mt-6 w-full text-right">
          <thead>
            <tr className="bg-slate-200 dark:bg-navy-800">
              <th className="px-4 py-2 text-left">Description</th>
              <th className="px-4 py-2">Qty.</th>
              <th className="px-4 py-2">Bid</th>
              <th className="px-4 py-2">Premium</th>
              <th className="px-4 py-2">Tax</th>
              <th className="px-4 py-2">Expenses</th>
              <th className="px-4 py-2">Total</th>
              {canEdit && (
                  <th></th>
              )}
            </tr>
          </thead>
          <tbody>
            {invoice.items.map((item) => {
              totals.qty += 1 * item.qty;
              totals.bid += 1 * item.bid;
              totals.premium += 1 * item.premium;
              totals.tax += 1 * item.tax;
              totals.expenses += 1 * item.expenses;
              totals.total += 1 * item.total;

              return (
                <tr key={item.id}>
                  <td className="px-4 py-2 text-left">
                    <b>
                      <Link
                        to={`/admin/lot/${item.lot_id}`}
                        className="text-slate-400 hover:text-slate-600"
                      >
                        {item.title}
                      </Link>
                    </b>
                    <br />
                    {item.description}
                  </td>
                  <td className="px-4 py-2 align-top">{item.qty}</td>
                  <td className="px-4 py-2 align-top">
                    {formatCurrency(item.bid, 2)}
                  </td>
                  <td className="px-4 py-2 align-top">
                    <div>{formatCurrency(item.premium, 2)}</div>

                    {canEdit && (
                      <button
                        onClick={() =>
                          handleEditClick(
                            {
                              premium: item.premium,
                              feeType: item.feeType || 'flat',
                              item,
                            },
                            'premium'
                          )
                        }
                        title="Override Premium"
                        className="inline-flex items-center gap-1 text-sm text-slate-400 hover:text-slate-600"
                      >
                        <span>Edit</span>
                        <FaPencil className="h-3 w-3" />
                      </button>
                    )}
                  </td>
                  <td className="px-4 py-2 align-top">
                    <div>{formatCurrency(item.tax, 2)}</div>

                    {canEdit && (
                      <button
                        onClick={() =>
                          handleEditClick(
                            {
                              tax: item.tax,
                              item,
                            },
                            'tax'
                          )
                        }
                        title="Edit Tax Rate"
                        className="inline-flex items-center gap-1 text-sm text-slate-400 hover:text-slate-600"
                      >
                        <span>Edit</span>
                        <FaPencil className="h-3 w-3" />
                      </button>
                    )}
                  </td>
                  <td className="px-4 py-2 align-top">
                    <div>{showRelatedExpenses(item.related_expenses)}</div>

                    {canEdit && (
                      <button
                        onClick={() =>
                          handleEditClick(
                            {
                              item_id: item.id,
                              expense_group_id: '',
                              amount: '',
                              description: '',
                              type: 'flat',
                              item,
                            },
                            'expenses'
                          )
                        }
                        title="Add Lot Expense"
                        className="inline-flex items-center gap-1 text-sm text-slate-400 hover:text-slate-600"
                      >
                        <span>Add</span>
                        <FaPlus className="h-3 w-3" />
                      </button>
                    )}
                  </td>
                  <td className="px-4 py-2 align-top">
                    {formatCurrency(item.total, 2)}

                    {item.bid == 0 && (
                        <>
                          <div className="tracking-wide text-4xs uppercase text-red-600">
                            {item.lot?.no_sale && 'No Sale'}
                          </div>
                        </>
                    )}
                  </td>
                  {canEdit && (
                      <td>
                        <InvoiceItemMenu
                            item={item}
                        />
                      </td>
                  )}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th className="px-4 py-2 text-left">Totals</th>
              <th className="px-4 py-2">{totals.qty}</th>
              <th className="px-4 py-2">{formatCurrency(totals.bid, 2)}</th>
              <th className="px-4 py-2">{formatCurrency(totals.premium, 2)}</th>
              <th className="px-4 py-2">{formatCurrency(totals.tax, 2)}</th>
              <th className="px-4 py-2">
                {formatCurrency(totals.expenses, 2)}
              </th>
              <th className="px-4 py-2">{formatCurrency(totals.total, 2)}</th>
            </tr>
          </tfoot>
        </table>
        <div className="flex gap-4 justify-between items-center">
          <div className="w-3/4">
            {invoice.auction?.config?.invoice_footer && (
                <div className="text-center w-1/2 mx-auto" dangerouslySetInnerHTML={{
                  __html: invoice.auction?.config?.invoice_footer
                }} />
            )}
          </div>
          <div className="w-1/4 mt-6 flex flex-col items-end gap-4 text-right">
            <div className="w-full">
              <div className="flex items-center justify-between gap-2 text-lg font-bold">
                <span>Expenses</span>

                {canEdit && (
                    <button
                        onClick={() =>
                            handleEditClick(
                                {
                                  expense_group_id: '',
                                  amount: '',
                                  description: '',
                                  type: 'flat',
                                },
                                'expenses'
                            )
                        }
                        title="Add Expense"
                        className="inline-flex items-center gap-1 rounded bg-slate-400 px-1.5 py-1 text-sm text-white hover:bg-slate-600"
                    >
                      <FaPlus className="h-4 w-4" />
                    </button>
                )}
              </div>

              {showRelatedExpenses(invoice.related_expenses)}
            </div>

            <div className="w-full">
              <div className="flex items-center justify-between gap-2 text-lg font-bold">
                <span>Payments</span>

                {canEdit && (
                    <button
                        onClick={() => {
                          setModalFieldValues({
                            payment_type_id: '',
                            payment_type_name: '',
                            amount: invoice.balance || 0.0,
                          });
                          setShowPaymentModal(true);
                        }}
                        title="Add Payment"
                        className="inline-flex items-center gap-1 rounded bg-slate-400 px-1.5 py-1 text-sm text-white hover:bg-slate-600"
                    >
                      <FaPlus className="h-4 w-4" />
                    </button>
                )}
              </div>

              {invoice.related_payments.map((payment) => (
                  <div
                      key={payment.id}
                      className={
                          'flex items-center justify-between gap-4 py-2 text-left ' +
                          (payment.deleted_at ? 'line-through' : '')
                      }
                  >
                    <div className="flex w-1/2 items-center gap-2">
                      {payment.type.name}

                      {canAccessStripe && payment.transaction?.stripe_url && (
                          <a
                              href={payment.transaction.stripe_url}
                              target="_blank"
                              className={payment.transaction.stripe_url}
                          >
                            <FaCcStripe className="text-3xl text-[#5433FF]" />
                          </a>
                      )}
                    </div>
                    <div className="w-1/4 text-right">
                      {formatCurrency(payment.amount)}
                    </div>
                    {canEdit && (
                        <div
                            className={
                                'flex items-center gap-2 ' +
                                (payment.deleted_at ? 'invisible' : '')
                            }
                        >
                          <button
                              onClick={() => {
                                setModalFieldValues({ ...payment });
                                setShowPaymentModal(true);
                              }}
                              className="text-slate-400 hover:text-slate-600"
                          >
                            <FaPencil />
                          </button>
                          <button
                              onClick={() => handleDeletePayment(payment.id)}
                              className="text-red-400 hover:text-red-600"
                          >
                            <FaTrashCan />
                          </button>
                        </div>
                    )}
                  </div>
              ))}

              {invoice.related_payments.length == 0 && <em>None</em>}
            </div>

            <table className="w-full">
              <tbody>
              <tr>
                <td>Subtotal:</td>
                <td>{formatCurrency(invoice.subtotal, 2)}</td>
              </tr>
              <tr>
                <td>Expenses:</td>
                <td>{formatCurrency(invoice.expenses, 2)}</td>
              </tr>
              <tr>
                <td>Taxes:</td>
                <td>{formatCurrency(invoice.taxes, 2)}</td>
              </tr>
              <tr className="border border-0 border-b border-slate-300">
                <th>Invoice Total:</th>
                <th>{formatCurrency(invoice.total, 2)}</th>
              </tr>
              <tr>
                <td>Payments:</td>
                <td>({formatCurrency(invoice.payments, 2)})</td>
              </tr>
              <tr>
                <td>Balance:</td>
                <td>{formatCurrency(invoice.balance, 2)}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
