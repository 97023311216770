import { useEffect, useState } from 'react'
import UserLayout from './components/UserLayout'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { get, bids as loadBids } from 'services/users'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { formatCurrency } from 'helpers/currency'
import { displayDatetime } from 'helpers/dates'

const UserBids = () => {
    const { id } = useParams()
    const [ user, setUser ] = useState(null)
    const [ bids, setBids ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)

    useEffect(() => {
        get(id, ({ data }) => {
            setUser(data.user)
        })
    }, [])

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )

        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        if (! user) {
            return
        }

        loadBids(user.id, {
            page,
        }, ({ data }) => {
            setBids(data.bids)
        })
    }, [user, page])

    const onPage = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: e.page + 1
        })
    }

    if (! user) {
        return
    }

    return (
        <UserLayout id={id} user={user}>
            <DataTable className="text-sm" value={bids.data} lazy dataKey="id" paginator
                       first={bids.from - 1} rows={bids.per_page} totalRecords={bids.total} onPage={onPage}
                       tableStyle={{ minWidth: '75rem' }}
            >
                <Column header="Auction"
                        body={(data) => {
                            return (
                                <Link to={`/admin/auction/${data.lot?.auction?.id}`} className="text-slate-400 hover:text-slate-600">{data.lot?.auction?.name}</Link>
                            )
                        }}
                />
                <Column header="Lot"
                        body={(data) => {
                            return (
                                <Link to={`/admin/lot/${data.lot?.id}`} className="text-slate-400 hover:text-slate-600">{data.lot?.name}</Link>
                            )
                        }}
                />
                <Column header="Amount"
                        body={(data) => {
                            return formatCurrency(data.bid)
                        }}
                />
                <Column field="dates.created"
                        header="Time Placed"
                        body={(bid) => {
                            return displayDatetime(bid.created_at)
                        }}
                />
                <Column header="IP"
                        body={(bid) => {
                            return (
                                <div>
                                    {bid.auto ? 'AUTOBID' : bid.ip}
                                </div>
                            )
                        }}
                />
            </DataTable>
        </UserLayout>
    )
}

export default UserBids