import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth, can } from "components/auth/AuthContext";
import { Portal } from "@chakra-ui/portal";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes";
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { checkAuth, logoutSuccess } from 'store/user'
import { AuthProvider } from 'components/auth/AuthContext'
import { NotificationsProvider } from 'components/notifications/NotificationsContext'
import NotificationArea from 'components/notifications/NotificationArea'
import { EmailProvider } from 'components/email/EmailContext'
import EmailModal from 'components/email/EmailModal'

const AdminRoutes = ({ routes }) => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && (! prop.permission || can(auth, prop.permission))) {
        return (
            <Route path={prop.path}
                   element={prop.component}
                   loader={prop.loader}
                   handle={prop.handle}
                   key={key} />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      return null;
    });
  };

  const auth = useAuth()
  const [ filteredRoutes, setFilteredRoutes ] = useState(
      getRoutes(routes)
  )

  useEffect(() => {
    setFilteredRoutes(
        getRoutes(routes)
    )
  }, [auth])

  return (
      <Routes>
        {filteredRoutes}
        <Route
            path="/"
            element={<Navigate to="/admin/dashboard" replace />}
        />
      </Routes>
  )
}

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [autoclose, setAutoclose] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState(null);
  const [checkedAuth, setCheckedAuth] = React.useState(false);
  const { user } = useSelector(state => state.user)

  React.useEffect(() => {
    const defaultClosedOnMobile = () => {
      if (window.innerWidth < 1200) {
        setOpen(false)
        setAutoclose(true)
      } else {
        setOpen(true)
        setAutoclose(false)
      }
    }

    window.addEventListener("resize", defaultClosedOnMobile);

    defaultClosedOnMobile();
  }, []);

  React.useEffect(() => {
    // Ensure invalid breadcrumbs get wiped out
    setCurrentRoute(null);

    getActiveRoute(routes);

    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, [location.pathname]);

  const check = window.location.search.indexOf('check') > 0

  React.useEffect(() => {
    if (! user || (check && ! checkedAuth)) {
      setCheckedAuth(true)

      dispatch(checkAuth(() => {
        //
      }, () => {
        navigate('/auth/login')
      }))
    }
  }, [user]);

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response?.status === 401) {
      dispatch(logoutSuccess())
    }

    return Promise.reject(error);
  });

  // functions for changing the states from components
  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          setCurrentRoute(routes[i].name);
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  document.documentElement.dir = "ltr";

  return (
    <NotificationsProvider>
      <EmailProvider>
      <AuthProvider>
        <div className="flex h-full w-full bg-background-100 dark:bg-background-900">
          <Sidebar
            open={open}
            hovered={hovered}
            setHovered={setHovered}
            mini={props.mini}
            onClose={() => setOpen(false)}
            autoclose={autoclose}
          />
          {/* Navbar & Main Content */}
          <div className="h-full w-full dark:bg-navy-900">
            {/* Main Content */}
            <main
              className={`mt-16 sm:mt-0 mx-2.5 flex-none transition-all dark:bg-navy-900 md:pr-2 ${
                props.mini === false
                  ? "xl:ml-[248px]"
                  : props.mini === true && hovered === true
                  ? "xl:ml-[248px]"
                  : "ml-0 xl:ml-[142px]"
              } `}
            >
              {/* Routes */}
              <div>
                <Portal>
                  <Navbar
                    onOpenSidenav={() => setOpen(!open)}
                    brandText={currentRoute}
                    secondary={getActiveNavbar(routes)}
                    theme={props.theme}
                    setTheme={props.setTheme}
                    hovered={hovered}
                    mini={props.mini}
                    setMini={props.setMini}
                    {...rest}
                  />
                </Portal>
                <div className="mx-auto min-h-screen p-2 !pt-[100px] md:p-2">
                  <AdminRoutes routes={routes} />
                </div>
                <div className="p-3">
                  <Footer />
                </div>
              </div>
            </main>
          </div>

          <NotificationArea />
          <EmailModal />
        </div>
      </AuthProvider>
      </EmailProvider>
    </NotificationsProvider>
  );
}
