import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { approvals as loadApprovals, approveChanges, listApprovalModified } from 'services/lots'
import Link from 'components/buttons/Link'
import LotLayout from './components/LotLayout'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import { displayDatetime } from 'helpers/dates'

const LotChangeHistory = () => {
    const { id } = useParams()
    const [ lot, setLot ] = useState(null)
    const [ approvals, setApprovals ] = useState([])
    const notify = useNotificationsDispatch()

    useEffect(() => {
        load(id)
    }, [id])

    const load = (id) => {
        loadApprovals(id, ({ data }) => {
            setLot(data.lot)
            setApprovals(data.approvals)
        })
    }

    const approve = (approval) => {
        approveChanges(id, () => {
            load(id)

            notify({
                message: 'Lot changes approved & applied.',
                title: 'Success!',
                type: 'success',
            })
        }, {
            approval_id: approval.id
        })
    }

    if (! lot) {
        return
    }

    return (
        <LotLayout id={id} lot={lot} setLot={setLot}>
            <div className="w-full">
                {approvals.length > 0 ? (
                    <table className="w-full text-left border-separate border-spacing-y-4">
                        <thead>
                            <tr className="uppercase text-xs tracking-widest">
                                <th>Changes</th>
                                <th>Requested</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        {approvals.map(approval => {
                            const modified = []

                            listApprovalModified(lot, approval, modified)

                            return (
                                <tr>
                                    <td>
                                        <div>
                                            <ul className="px-2">
                                                {modified.map((attr, i) => (
                                                    <li key={(attr.item ? 'item' : 'lot') + '-' + i}>
                                                        <span className="capitalize">{attr.k}</span>: {attr.v}
                                                    </li>
                                                ))}
                                                {modified.length == 0 && (
                                                    <span className="italic">No changes from current</span>
                                                )}
                                            </ul>
                                        </div>
                                    </td>
                                    <td>{displayDatetime(approval.created_at)}</td>
                                    <td>
                                        {! approval.approved_at && ! approval.rejected_at && (
                                            <Link to={`/admin/lot/${id}/edit`}>
                                                Pending
                                            </Link>
                                        )}
                                        {approval.approved_at && 'Approved'}
                                        {approval.rejected_at && (
                                            <Link title="Approve Changes" onClick={() => approve(approval)}>
                                                Rejected
                                            </Link>
                                        )}
                                        {approval.reviewer && (
                                            <>
                                                <span className="mx-1">by</span>
                                                <Link target="_blank" to={`/admin/user/${approval.reviewed_by}`}>
                                                    {approval.reviewer.name}
                                                </Link>
                                                <br />
                                                <small>{displayDatetime(approval.approved_at || approval.rejected_at)}</small>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                ) : (
                    <p>No results</p>
                )}
            </div>
        </LotLayout>
    )
}

export default LotChangeHistory