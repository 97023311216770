import { useNotifications } from './NotificationsContext';
import Notification from './Notification';

export default () => {
  const notifications = useNotifications();

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed right-0 top-[100px] z-10 flex w-96 items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {notifications.map((notification, i) => (
            <Notification key={i} notification={notification} />
          ))}
        </div>
      </div>
    </>
  );
};
