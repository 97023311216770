import { Link } from 'react-router-dom'

const Button = ({ children, color, size = 'lg', invisible = false, ...props }) => {
    let className, textSize

    if (size === 'xs') {
        className = 'px-1 py-0.5'
        textSize = 'text-xs'
    } else if (size === 'sm') {
        className = 'px-2 py-1'
        textSize = 'text-sm'
    } else if (size === 'md') {
        className = 'px-3 py-2'
        textSize = 'text-sm'
    } else {
        className = 'px-4 py-2'
        textSize = 'text-base'
    }

    if (color === 'outline') {
        className += ` inline-flex gap-2 rounded-xl border border-solid border-brand-500 ${textSize} font-medium text-brand-500 duration-200 hover:bg-brand-100`
    } else if (color === 'red-outline') {
        className += ` inline-flex gap-2 rounded-xl border border-solid border-red-500 ${textSize} font-medium text-red-500 duration-200 hover:bg-red-100`
    } else if (color === 'green-outline') {
        className += ` inline-flex gap-2 rounded-xl border border-solid border-green-500 ${textSize} font-medium text-green-500 duration-200 hover:bg-green-100`
    } else {
        className += ` inline-block rounded-xl ${textSize} font-medium transition duration-200`

        if (color === 'red') {
            className += ' bg-red-500 text-white hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200'
        } else if (color === 'amber') {
            className += ' bg-amber-500 text-white hover:bg-amber-600 active:bg-amber-700 dark:bg-amber-400 dark:text-white dark:hover:bg-amber-300 dark:active:bg-amber-200'
        } else if (color === 'black') {
            className += ' bg-black text-white hover:bg-brand-800 active:bg-brand-900 dark:bg-brand-600 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400'
        } else {
            className += ' bg-brand-500 text-white hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
        }
    }

    if (invisible) {
        className += ' invisible'
    } else if (props.disabled) {
        className += ' opacity-50 hover:cursor-progress'
    } else {
        className += ' hover:cursor-pointer'
    }

    if (props.to) {
        return (
            <Link className={className} {...props}>
                {children}
            </Link>
        )
    }

    if (props.href) {
        return (
            <a className={className} {...props}>
                {children}
            </a>
        )
    }

    return (
        <button
            className={className}
            {...props}
        >
            {children}
        </button>
    )
}

export default Button