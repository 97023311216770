import Countdown from 'react-countdown'

const CountdownDisplay = ({ date, onComplete = () => {} }) => {
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>ended</span>
        } else if (days) {
            return <span>{days} d {hours} h {minutes} m {seconds} s</span>
        } else {
            return <span>{hours} h {minutes} m {seconds} s</span>
        }
    };

    return (
        <div>
            <Countdown
                date={Date.parse(date)}
                renderer={renderer}
                onComplete={onComplete}
            />
        </div>
    )
}

export default CountdownDisplay