import { useCallback, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { debounce } from 'lodash';
import axios from 'axios';
import Link from 'components/buttons/Link';

const Search = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({});
  const [focused, setFocused] = useState(false);

  const searchRequest = useCallback(
    debounce(async (query) => {
      if (query.length < 3) {
        setResults({});
        return;
      }

      const { data: results } = await axios.get(
        process.env.REACT_APP_API_URL + '/search',
        {
          params: { query },
        }
      );
      setResults(results);
    }, 400),
    []
  );

  useEffect(() => {
    searchRequest(query);
  }, [query]);

  return (
    <div className="relative h-full">
      <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search..."
          className="block h-full w-full rounded-full border-0 bg-lightPrimary p-0 text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 focus:outline-none focus:ring-0 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={(e) => {
            setFocused(true);
          }}
          onBlur={(e) => {
            setTimeout(() => {
              if (e.target !== document.activeElement) {
                setFocused(false);
              }
            }, 200);
          }}
        />
      </div>
      {focused && (
        <div className="absolute left-0 top-14 z-50 -mt-1 w-full bg-white text-xs shadow-xl dark:bg-navy-900 dark:text-white xl:w-[230px]">
          {results.auctions?.length > 0 && (
            <div className="p-2">
              <h4 className="px-1 pb-2 font-bold">Auctions</h4>
              {results.auctions.map((auction) => (
                <Link
                  to={`/admin/auction/${auction.id}`}
                  key={auction.id}
                  className="block p-1 text-slate-600 hover:bg-slate-100 hover:text-slate-800 dark:text-white dark:hover:bg-navy-600"
                >
                  {auction.name}
                </Link>
              ))}
            </div>
          )}
          {results.invoices?.length > 0 && (
            <div className="p-2">
              <h4 className="px-1 pb-2 font-bold">Invoices</h4>
              {results.invoices.map((invoice) => (
                <Link
                  to={`/admin/invoice/${invoice.id}/edit`}
                  key={invoice.id}
                  className="block p-1 text-slate-600 hover:bg-slate-100 hover:text-slate-800 dark:text-white dark:hover:bg-navy-600"
                >
                  {invoice.number}
                </Link>
              ))}
            </div>
          )}
          {results.lots?.length > 0 && (
            <div className="p-2">
              <h4 className="px-1 pb-2 font-bold">Lots</h4>
              {results.lots.map((lot) => (
                <Link
                  to={`/admin/lot/${lot.id}`}
                  key={lot.id}
                  className="block p-1 text-slate-600 hover:bg-slate-100 hover:text-slate-800 dark:text-white dark:hover:bg-navy-600"
                >
                  {lot.name}
                </Link>
              ))}
            </div>
          )}
          {results.items?.length > 0 && (
            <div className="p-2">
              <h4 className="px-1 pb-2 font-bold">Inventory</h4>
              {results.items.map((item) => (
                <Link
                  to={`/admin/item/${item.id}`}
                  key={item.id}
                  className="block p-1 text-slate-600 hover:bg-slate-100 hover:text-slate-800 dark:text-white dark:hover:bg-navy-600"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          )}
          {results.clients?.length > 0 && (
            <div className="p-2">
              <h4 className="px-1 pb-2 font-bold">Clients</h4>
              {results.clients.map((client) => (
                <Link
                  to={`/admin/client/${client.id}`}
                  key={client.id}
                  className="block p-1 text-slate-600 hover:bg-slate-100 hover:text-slate-800 dark:text-white dark:hover:bg-navy-600"
                >
                  {client.name}
                </Link>
              ))}
            </div>
          )}
          {results.companies?.length > 0 && (
            <div className="p-2">
              <h4 className="px-1 pb-2 font-bold">Companies</h4>
              {results.companies.map((company) => (
                <Link
                  to={`/admin/company/${company.id}`}
                  key={company.id}
                  className="block p-1 text-slate-600 hover:bg-slate-100 hover:text-slate-800 dark:text-white dark:hover:bg-navy-600"
                >
                  {company.name}
                </Link>
              ))}
            </div>
          )}
          {results.users?.length > 0 && (
            <div className="p-2">
              <h4 className="px-1 pb-2 font-bold">Users</h4>
              {results.users.map((user) => (
                <Link
                  to={`/admin/user/${user.id}`}
                  key={user.id}
                  className="block p-1 text-slate-600 hover:bg-slate-100 hover:text-slate-800 dark:text-white dark:hover:bg-navy-600"
                >
                  {user.name}
                </Link>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
