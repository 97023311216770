import InputField from 'components/fields/InputField';

const DateRangeFilter = ({ from, to, setFilter }) => {
  return (
    <div className="mb-4 flex items-center gap-4">
      <div className="flex items-center gap-2">
        <label htmlFor="date_range_from">From</label>
        {/* <input
                    id="date_range_from"
                    type="date"
                    value={from}
                    onChange={(e) => {
                        setFilter('from', e.target.value)
                    }}
                /> */}

        <InputField
          id="date_range_from"
          type="date"
          value={from}
          onChange={(e) => {
            setFilter('from', e.target.value);
          }}
        />
      </div>

      <div className="flex items-center gap-2">
        <label htmlFor="date_range_to">To</label>
        {/* <input
          id="date_range_to"
          type="date"
          value={to}
          onChange={(e) => {
            setFilter('to', e.target.value);
          }}
        /> */}
        <InputField
          id="date_range_to"
          type="date"
          value={to}
          onChange={(e) => {
            setFilter('to', e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default DateRangeFilter;
