import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
    get,
    update,
    createExpense,
    createPayment,
    deleteExpense,
    deletePayment,
    updateExpense,
    updatePayment
} from 'services/statements'
import Breadcrumbs from 'components/navbar/Breadcrumbs'
import { formatCurrency } from 'helpers/currency'
import { FaExternalLinkAlt } from 'react-icons/fa'
import {
    FaPencil,
    FaPlus,
    FaTrashCan
} from 'react-icons/fa6'
import ModalBillingAddress from '../invoices/components/ModalBillingAddress'
import ModalPremium from '../invoices/components/ModalPremium'
import ModalExpenses from '../invoices/components/ModalExpenses'
import AddPaymentModal from '../invoices/components/AddPaymentModal'
import { can, useAuth } from 'components/auth/AuthContext'
import { displayDatetime } from 'helpers/dates'
import logo from 'assets/img/peak/logo.svg'

const Edit = () => {
    const { id } = useParams()
    const [ statement, setStatement ] = useState(null)

    const [showBillingModal, setShowBillingModal] = useState(false)
    const [showCommissionModal, setShowCommissionModal] = useState(false)
    const [showExpensesModal, setShowExpensesModal] = useState(false)
    const [showCreditsModal, setShowCreditsModal] = useState(false)
    const [showPaymentModal, setShowPaymentModal] = useState(false)

    const [modalFieldValues, setModalFieldValues] = useState({})

    const auth = useAuth()
    const canEdit = can(auth, 'update_statements')

    const loadStatement = () => {
        get(id, ({ data }) => {
            setStatement(data.statement)
        });
    };

    useEffect(() => {
        loadStatement()
    }, [])

    if (! statement) {
        return
    }

    const billingAddress = statement.addresses.filter(address => address.type === 'Billing')[0]

    const totals = {
        qty: 0,
        bid: 0,
        commission: 0,
        expenses: 0,
        total: 0
    }

    const handleEditClick = (fieldValues, modal) => {
        setModalFieldValues(fieldValues);

        if (modal === 'billing') {
            setShowBillingModal(true);
        } else if (modal === 'commission') {
            setShowCommissionModal(true);
        } else if (modal === 'expenses') {
            setShowExpensesModal(true);
        } else if (modal === 'credits') {
            setShowCreditsModal(true);
        }
    };

    const setData = (key, value) => {
        setModalFieldValues({
            ...modalFieldValues,
            [key]: value
        });
    };

    const handleSave = async (newValues, type) => {
        update(id, {...newValues, type: type}, ({ data }) => {
            setStatement(data.statement);
        })
    };

    const handleSaveCommission = async (newValues) => {
        update(id, newValues, ({ data }) => {
            loadStatement();
        });
    };

    const handleSaveExpenses = async (newValues) => {
        if (newValues.id) {
            updateExpense(id, newValues.id, newValues, ({ data }) => {
                loadStatement();
            });
        } else {
            createExpense(id, newValues, ({ data }) => {
                loadStatement();
            });
        }
    };

    const handleDeleteExpense = (expense_id) => {
        if (!window.confirm('Are you sure you want to delete?')) {
            return;
        }

        deleteExpense(id, expense_id, () => {
            loadStatement();
        });
    };

    const handleSubmitPayment = (id, data, then) => {
        if (data.id) {
            updatePayment(id, data.id, data, (response) => {
                then(response);
                loadStatement();
            });
        } else {
            createPayment(id, data, (response) => {
                then(response);
                loadStatement();
            });
        }
    };

    const handleDeletePayment = (payment_id) => {
        if (!window.confirm('Are you sure you want to delete?')) {
            return;
        }

        deletePayment(id, payment_id, () => {
            loadStatement();
        });
    };

    const showRelatedExpenses = (expenses, credit = false) => {
        return (
            <>
                {expenses.map((expense) => (
                    <div
                        key={expense.id}
                        className="flex items-start justify-between gap-4 pb-2 text-left min-w-48"
                    >
                        {expense.description && (
                            <div className="w-1/2 text-xs leading-loose">{expense.description}</div>
                        )}
                        <div className="w-1/4 text-right">
                            {formatCurrency(credit ? Math.abs(expense.calculated_amount) : expense.calculated_amount)}
                        </div>
                        {canEdit && (
                            <div className="flex items-center gap-2 pt-1">
                                <button
                                    onClick={() => handleEditClick({
                                        ...expense,
                                        credit
                                    }, credit ? 'credits' : 'expenses')}
                                    className="text-slate-400 hover:text-slate-600"
                                >
                                    <FaPencil />
                                </button>
                                <button
                                    onClick={() => handleDeleteExpense(expense.id)}
                                    className="text-red-400 hover:text-red-600"
                                >
                                    <FaTrashCan />
                                </button>
                            </div>
                        )}
                    </div>
                ))}

                {expenses.length == 0 && <em>None</em>}
            </>
        );
    };

    return (
        <div>
            <ModalBillingAddress
                show={showBillingModal}
                onClose={() => setShowBillingModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSave}
                setData={setData}
            />

            <ModalPremium
                title="Override Commission"
                formType="Commission"
                show={showCommissionModal}
                onClose={() => setShowCommissionModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSaveCommission}
                setData={setData}
            />

            <ModalExpenses
                statement={statement}
                show={showExpensesModal}
                onClose={() => setShowExpensesModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSaveExpenses}
                setData={setData}
            />

            <ModalExpenses
                statement={statement}
                type="Credit"
                show={showCreditsModal}
                onClose={() => setShowCreditsModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSaveExpenses}
                setData={setData}
            />

            <AddPaymentModal
                statement={statement}
                payment={modalFieldValues}
                visible={showPaymentModal}
                setVisible={setShowPaymentModal}
                submit={handleSubmitPayment}
            />

            <div className="ml-[11px] mt-[-72px] fixed z-10">
                <Breadcrumbs trail={[
                    {
                        href: "/admin/auctions",
                        title: "Auctions"
                    },
                    {
                        href: `/admin/auction/${statement.auction_id}`,
                        title: statement.auction.name
                    },
                    {
                        href: `/admin/auction/${statement.auction_id}/statements`,
                        title: "Statements"
                    },
                    {
                        href: "#",
                        title: `Statement #${statement.number}`
                    }
                ]} />
            </div>

            <div className="mt-3 w-full">
                <div className="flex items-center justify-between gap-12 pt-6 px-4">
                    <div className="w-32">
                        <img src={logo} alt="Peak Auto Auctions" className="w-full" />
                    </div>
                    <div>
                        <div className="text-lg font-bold flex gap-2 items-center">
                            Billing Address

                            {canEdit && (
                                <button
                                    onClick={() => handleEditClick({
                                        first_name: billingAddress?.first_name || '',
                                        last_name: billingAddress?.last_name || '',
                                        address_line1: billingAddress?.address_line1 || '',
                                        address_line2: billingAddress?.address_line2 || '',
                                        city: billingAddress?.city || '',
                                        state: billingAddress?.state || '',
                                        zip: billingAddress?.zip || ''
                                    }, 'billing')}
                                    className="text-slate-400 hover:text-slate-600">
                                    <FaPencil className="w-4 h-4" />
                                </button>
                            )}

                            {canEdit && (
                                <Link to={`/admin/company/${statement.consignor_id}/edit`}
                                      className="text-slate-400 hover:text-slate-600">
                                    <FaExternalLinkAlt className="w-4 h-4" />
                                </Link>
                            )}
                        </div>
                        {billingAddress ? (
                            <div>
                                {billingAddress.first_name} {billingAddress.last_name}<br />
                                {billingAddress.address_line1}<br />
                                {billingAddress.address_line2 && (
                                    <div>
                                        {billingAddress.address_line2}
                                    </div>
                                )}
                                {billingAddress.city}, {billingAddress.state} {billingAddress.zip}
                            </div>
                        ) : 'N/A'}
                    </div>
                    <div>
                        {displayDatetime(statement.created_at)}
                    </div>
                    <div
                        className={
                            'text-2xl font-bold uppercase ' +
                            (statement.status === 'paid' ? 'text-green-600' : 'text-red-600')
                        }
                    >
                        {statement.status}
                    </div>
                </div>
                <table className="mt-6 w-full text-right">
                    <thead>
                    <tr className="bg-slate-200">
                        <th className="px-4 py-2 text-left">Description</th>
                        <th className="px-4 py-2">Qty.</th>
                        <th className="px-4 py-2">Bid</th>
                        <th className="px-4 py-2">Commission</th>
                        <th className="px-4 py-2">Expenses</th>
                        <th className="px-4 py-2">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {statement.items.map(item => {
                        totals.qty += 1 * item.qty
                        totals.bid += 1 * item.bid
                        totals.commission += 1 * item.commission
                        totals.expenses += 1 * item.expenses
                        totals.total += 1 * item.total

                        return (
                            <tr key={item.id}>
                                <td className="px-4 py-2 text-left">
                                    <b>
                                        <Link
                                            to={`/admin/lot/${item.lot_id}`}
                                            className="text-slate-400 hover:text-slate-600"
                                        >{item.title}</Link>
                                    </b><br />
                                    {item.description}
                                </td>
                                <td className="px-4 py-2 align-top">{item.qty}</td>
                                <td className="px-4 py-2 align-top">
                                    {item.lot?.reserve_status === 'not_met' ? (
                                        <>
                                            <div>
                                                {formatCurrency(item.bid, 2)}
                                            </div>
                                            {item.lot?.winning_bid?.bid && (
                                                <div className="tracking-wide text-4xs uppercase">
                                                    Top bid: {formatCurrency(item.lot?.winning_bid?.bid, 2)}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {formatCurrency(item.bid, 2)}
                                        </>
                                    )}
                                </td>
                                <td className="px-4 py-2 align-top">
                                    <div>
                                        {formatCurrency(item.commission, 2)}
                                    </div>

                                    {canEdit && (
                                        <button onClick = {() => handleEditClick({
                                            commission: item.commission,
                                            feeType: item.feeType || 'flat',
                                            item,
                                        }, 'commission')}
                                                title="Override Commission"
                                                className="text-sm inline-flex gap-1 items-center text-slate-400 hover:text-slate-600"
                                        >
                                            <span>Edit</span>
                                            <FaPencil className="w-3 h-3" />
                                        </button>
                                    )}
                                </td>
                                <td className="px-4 py-2 align-top">
                                    <div>
                                        {showRelatedExpenses(item.related_expenses)}
                                    </div>

                                    {canEdit && (
                                        <button
                                            onClick={() => {
                                                handleEditClick(
                                                    {
                                                        item_id: item.id,
                                                        expense_group_id: '',
                                                        amount: '',
                                                        description: '',
                                                        type: 'flat',
                                                        item,
                                                    },
                                                    'expenses'
                                                )
                                            }}
                                            title="Add Lot Expense"
                                            className="text-sm inline-flex gap-1 items-center text-slate-400 hover:text-slate-600"
                                        >
                                            <span>Add</span>
                                            <FaPlus className="w-3 h-3" />
                                        </button>
                                    )}
                                </td>
                                <td className="px-4 py-2 align-top">
                                    <div>
                                        {formatCurrency(item.total, 2)}
                                    </div>

                                    {item.bid == 0 && (
                                        <>
                                            <div className="tracking-wide text-4xs uppercase text-red-600">
                                                {item.lot?.no_sale ? 'No Sale' : (
                                                    item.lot?.counts?.bids == 0 ? (
                                                        <>
                                                            No Bid
                                                        </>
                                                    ) : (
                                                        <>
                                                            {item.lot?.reserve_status === 'not_met' && 'Reserve Not Met'}
                                                        </>
                                                    )
                                                )}
                                            </div>
                                        </>
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th className="px-4 py-2 text-left">Totals</th>
                        <th className="px-4 py-2">{totals.qty}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.bid, 2)}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.commission, 2)}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.expenses, 2)}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.total, 2)}</th>
                    </tr>
                    </tfoot>
                </table>
                <div className="flex gap-4 justify-between items-center">
                    <div className="w-3/4">
                    </div>
                    <div className="w-1/4 mt-6 flex flex-col items-end gap-4 text-right">
                        <div className="w-full">
                            <div className="text-lg font-bold flex gap-2 items-center">
                            <span>
                                Expenses
                            </span>

                                {canEdit && (
                                    <button
                                        onClick={() =>
                                            handleEditClick(
                                                {
                                                    expense_group_id: '',
                                                    amount: '',
                                                    description: '',
                                                    type: 'flat',
                                                },
                                                'expenses'
                                            )
                                        }
                                        title="Add Expense"
                                        className="text-white text-sm inline-flex gap-1 items-center bg-slate-400 hover:bg-slate-600 rounded px-1.5 py-1"
                                    >
                                        <FaPlus className="w-4 h-4" />
                                    </button>
                                )}
                            </div>

                            {showRelatedExpenses(statement.related_expenses)}
                        </div>
                        <div className="w-full">
                            <div className="text-lg font-bold flex gap-2 items-center">
                            <span>
                                Credits
                            </span>

                                {canEdit && (
                                    <button
                                        onClick={() =>
                                            handleEditClick(
                                                {
                                                    expense_group_id: '',
                                                    amount: '',
                                                    description: '',
                                                    type: 'flat',
                                                    credit: true
                                                },
                                                'credits'
                                            )
                                        }
                                        title="Add Credit"
                                        className="text-white text-sm inline-flex gap-1 items-center bg-slate-400 hover:bg-slate-600 rounded px-1.5 py-1"
                                    >
                                        <FaPlus className="w-4 h-4" />
                                    </button>
                                )}
                            </div>

                            {showRelatedExpenses(statement.related_credits, true)}
                        </div>
                        <div className="w-full">
                            <div className="text-lg font-bold flex gap-2 items-center">
                            <span>
                                Payments
                            </span>

                                {canEdit && (
                                    <button
                                        onClick={() => {
                                            setModalFieldValues({
                                                payment_type_id: '',
                                                payment_type_name: '',
                                                amount: statement.balance || 0.0,
                                            });
                                            setShowPaymentModal(true);
                                        }}
                                        title="Add Payment"
                                        className="text-white text-sm inline-flex gap-1 items-center bg-slate-400 hover:bg-slate-600 rounded px-1.5 py-1"
                                    >
                                        <FaPlus className="w-4 h-4" />
                                    </button>
                                )}
                            </div>

                            {statement.related_payments.map((payment) => (
                                <div
                                    key={payment.id}
                                    className={
                                        'flex items-center justify-between gap-4 py-2 text-left ' +
                                        (payment.deleted_at ? 'line-through' : '')
                                    }
                                >
                                    <div className="flex w-1/2 items-center gap-2">
                                        {payment.type.name}
                                    </div>
                                    <div className="w-1/4 text-right">
                                        {formatCurrency(payment.amount)}
                                    </div>
                                    {canEdit && (
                                        <div
                                            className={
                                                'flex items-center gap-2 ' +
                                                (payment.deleted_at ? 'invisible' : '')
                                            }
                                        >
                                            <button
                                                onClick={() => {
                                                    setModalFieldValues({ ...payment });
                                                    setShowPaymentModal(true);
                                                }}
                                                className="text-slate-400 hover:text-slate-600"
                                            >
                                                <FaPencil />
                                            </button>
                                            <button
                                                onClick={() => handleDeletePayment(payment.id)}
                                                className="text-red-400 hover:text-red-600"
                                            >
                                                <FaTrashCan />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))}

                            {statement.related_payments.length == 0 && <em>None</em>}
                        </div>
                        <table className="w-full">
                            <tbody>
                            <tr>
                                <td>Subtotal:</td>
                                <td>{formatCurrency(statement.subtotal, 2)}</td>
                            </tr>
                            <tr>
                                <td>Expenses:</td>
                                <td>({formatCurrency(statement.expenses, 2)})</td>
                            </tr>
                            <tr>
                                <td>Credits:</td>
                                <td>{formatCurrency(Math.abs(statement.credits) || '0.00', 2)}</td>
                            </tr>
                            <tr className="border border-0 border-b border-slate-300">
                                <th>Statement Total:</th>
                                <th>{formatCurrency(statement.total, 2)}</th>
                            </tr>
                            <tr>
                                <td>Payments:</td>
                                <td>({formatCurrency(statement.payments, 2)})</td>
                            </tr>
                            <tr>
                                <td>Balance:</td>
                                <td>{formatCurrency(statement.balance, 2)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Edit