import { useEffect, useState } from 'react';
import { useAuth, can } from 'components/auth/AuthContext';
import { active } from 'services/auctions';
import { loadDashboardStats } from 'services/stats';
import Button from 'components/buttons/Button';
import { AuctionStats } from '../../auctions/components/AuctionStats';
import {
  FaChartLine,
  FaChevronRight,
  FaClock,
  FaBoxesStacked,
  FaChalkboardUser,
  FaUsers,
} from 'react-icons/fa6';
import { MdBusiness, MdGavel } from 'react-icons/md';
import { displayDatetime } from 'helpers/dates';
import { formatCurrency } from 'helpers/currency';
import pluralize from 'pluralize';

const Dashboard = () => {
  const auth = useAuth();
  const canViewGlobalStats = can(auth, 'view_global_stats');
  const canViewOpsReport = can(auth, 'view_ops_report');

  const [auctions, setAuctions] = useState([]);
  const [counts, setCounts] = useState({});
  const [stats, setStats] = useState([]);

  useEffect(() => {
    active(({ data }) => {
      setAuctions(data.auctions);
    });

    loadDashboardStats(({ data }) => {
      setCounts(data);
    });
  }, []);

  useEffect(() => {
    setStats(buildStats());
  }, [counts]);

  const buildStats = () => {
    let stats = [
      {
        icon: MdGavel,
        title: 'Active Auctions',
        value: counts.active_auctions || 0,
      },
      {
        icon: FaBoxesStacked,
        title: 'Inventory On Hand',
        value: counts.inventory_on_hand || 0,
      },
    ];

    if (canViewGlobalStats) {
      stats = [
        ...stats,
        {
          icon: FaUsers,
          title: 'Total Users',
          value: counts.total_users || 0,
        },
        {
          icon: MdBusiness,
          title: 'Total Companies',
          value: counts.total_companies || 0,
        },
        {
          icon: FaChalkboardUser,
          title: 'Logged In Users',
          value: counts.logged_in_users || 0,
        },
      ];
    }

    return stats;
  };

  return (
    <div className="mt-6">
      <div className="pl-2">
        <AuctionStats stats={stats} condensed={true} />
      </div>

      <div className="pt-8">
        <div className="mb-4 flex flex-col items-center gap-2 sm:flex-row sm:gap-8">
          <h1 className="text-[33px] font-bold capitalize text-navy-700 dark:text-white">
            Active Auctions
          </h1>

          {canViewOpsReport && (
            <Button href="/admin/reports/ops">
              <span className="inline-flex items-center gap-2">
                <span>Daily Ops Report</span>
                <FaChevronRight className="h-5 w-5" />
              </span>
            </Button>
          )}
        </div>

        <div className="grid grid-cols-1 items-center gap-4 xs:grid-cols-2 sm:grid-cols-5 sm:gap-8">
          {auctions.length == 0 && (
            <p className="italic dark:text-white">
              You have no active auctions
            </p>
          )}
          {auctions.map((auction) => (
            <a
              key={auction.id}
              href={'/admin/auction/' + auction.id}
              className="group flex h-full flex-col justify-end rounded-lg bg-white text-center shadow-lg dark:bg-black dark:text-white"
            >
              <h3 className="text-neutral-900 mb-2 mt-4 text-lg font-bold uppercase">
                {auction.name}
              </h3>

              <div className="aspect-h-1 aspect-w-1 xl:aspect-h-8 xl:aspect-w-7 h-[160px] w-full overflow-hidden rounded-lg rounded-t-lg">
                <img
                  src={auction.image?.src}
                  alt={auction.image?.alt}
                  className="mx-auto h-full max-w-[90%] object-contain object-center group-hover:opacity-75"
                />
              </div>

              <div className="flex flex-col items-center gap-2 pb-4 pt-2 text-xs">
                <div className="flex items-center gap-2">
                  <MdGavel className="h-4 w-4" />
                  {auction.counts.lots} {pluralize('lot', auction.counts.lots)}
                  {', '}
                  {auction.counts.views}{' '}
                  {pluralize('view', auction.counts.views)}
                  {', '}
                  {auction.counts.watches}{' '}
                  {pluralize('watch', auction.counts.watches)}
                </div>

                <div className="flex items-center gap-2">
                  <FaChartLine className="h-4 w-4" />
                  {auction.counts.bids} {pluralize('bid', auction.counts.bids)}{' '}
                  for a total of{' '}
                  {formatCurrency(auction.stats.bid_total, 0) || '$0.00'}
                </div>

                <div className="flex items-center gap-2">
                  <FaUsers className="h-4 w-4" />
                  {auction.counts.bidders} registered bidders
                </div>

                <div className="flex items-center gap-2">
                  <FaClock className="h-4 w-4" />
                  <span className="text-neutral-700">Ends:</span>{' '}
                  {displayDatetime(auction.dates.local.ends_at)}
                </div>
              </div>

              <span className="flex items-center justify-center gap-1 rounded-b-lg bg-slate-800 py-1 uppercase text-white group-hover:bg-slate-700">
                View Auction <FaChevronRight className="h-4 w-4" />
              </span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
