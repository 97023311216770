import Button from 'components/buttons/Button';

const ActionPanel = ({ children, title, action, color, onClick }) => {
  return (
    <div className="mt-4 bg-slate-50 shadow dark:bg-slate-800 sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="max-w-2xl text-sm text-slate-500 dark:text-slate-100">
            <h3 className="mb-2 text-base font-semibold leading-6 text-slate-900 dark:text-slate-50">
              {title}
            </h3>
            <p>{children}</p>
          </div>
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            <Button color={color} onClick={onClick}>
              {action}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionPanel;
