import {useEffect, useMemo, useRef, useState} from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import { formatCurrency } from 'helpers/currency'
import {FilterMatchMode} from 'primereact/api'

import {
    FaPencil,
    FaEllipsisVertical, FaMagnifyingGlass
} from "react-icons/fa6";
import {debounce} from 'lodash'
import {InputText} from 'primereact/inputtext'
import { can, useAuth } from 'components/auth/AuthContext'
import { displayDatetime } from 'helpers/dates'

const StatementsTable = ({ loadStatements }) => {
    const [ statements, setStatements ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const navigate = useNavigate()
    const [ filters, setFilters ] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'statements.number': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'auctions.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'companies.consignor_code': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'companies.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    })
    const [ globalFilterValue, setGlobalFilterValue ] = useState('')

    const auth = useAuth()
    const canEdit = can(auth, 'update_invoices')

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )

        setSort({
            by: searchParams.get('by'),
            dir: searchParams.get('dir')
        })

        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        loadStatements({
            page,
            by: sort.by,
            dir: sort.dir,
            filters: JSON.stringify(filters)
        }, ({ data }) => {
            setStatements(data.statements)
        })
    }, [page, sort, filters])

    const onPage = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: e.page + 1
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const onFilter = (e) => {
        setFilters(e.filters)
    }

    const debouncedFilter = useMemo(
        () => debounce(e => {
            onFilter(e)
        }, 500),
        []
    )

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        debouncedFilter({
            filters: _filters
        });
        setGlobalFilterValue(value);
    }

    const loading = false

    const renderHeader = () => {
        return (
            <div className="flex gap-4 items-center">
                <span className="p-icon-field p-icon-field-left">
                    <FaMagnifyingGlass className="w-5 h-5 p-input-icon" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>
            </div>
        )
    }

    const header = renderHeader()

    const MenuBodyTemplate = (rowData) => {
        const menu = useRef(null);
        const items = [
            canEdit ? {
                label: 'Edit',
                icon: <FaPencil className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/statement/${rowData.id}/edit`)
                }
            } :  {
                label: 'View',
                icon: <FaMagnifyingGlass className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/statement/${rowData.id}/edit`)
                }
            },
        ]

        return (
            <>
                <Menu model={items} popup ref={menu} id={"popup_menu_" + rowData.id} popupAlignment="right" />
                <Button text icon={(
                    <FaEllipsisVertical className="w-5 h-5" />
                )} className="mr-2" onClick={(event) => menu.current.toggle(event)} aria-controls={"popup_menu_" + rowData.id} aria-haspopup />
            </>
        )
    }

    const currency = (data, field) => {
        return formatCurrency(data[field])
    }

    return (
        <DataTable className="text-sm" value={statements.data} lazy dataKey="id" paginator
                   filters={filters} filterDisplay="menu" onFilter={onFilter} globalFilterFields={['statements.number', 'auctions.name', 'companies.consignor_code', 'companies.name']}
                   first={statements.from - 1} rows={statements.per_page} totalRecords={statements.total} onPage={onPage}
                   onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                   rowClassName="cursor-pointer"
                   onRowClick={(e) => {
                       navigate(`/admin/statement/${e.data.id}/edit`)
                   }}
                   loading={loading} tableStyle={{ minWidth: '75rem' }}
                   header={header}
        >
            <Column field="number" sortField="statements.number" filterField="statements.number" header="Number" sortable filter
                    body={(data) => {
                        return (
                            <Link to={`/admin/statement/${data.id}/edit`} className="text-slate-400 hover:text-slate-600">{data.number}</Link>
                        )
                    }}
            />
            <Column field="auction.name" sortField="auctions.name" filterField="auctions.name" sortable filter header="Auction"
                    body={(data) => {
                        return (
                            <Link to={`/admin/auction/${data.auction_id}`} className="text-slate-400 hover:text-slate-600">{data.auction.name}</Link>
                        )
                    }}
            />
            <Column field="consignor.consignor_code" sortField="companies.consignor_code" filterField="companies.consignor_code" sortable filter header="C. Code"
                    body={(data) => {
                        return (
                            <Link to={`/admin/company/${data.consignor_id}`} className="text-slate-400 hover:text-slate-600">{data.consignor.consignor_code}</Link>
                        )
                    }}
            />
            <Column field="consignor.name" sortField="companies.name" filterField="companies.name" sortable filter header="Consignor"
                    body={(data) => {
                        return (
                            <Link to={`/admin/company/${data.consignor_id}`} className="text-slate-400 hover:text-slate-600">{data.consignor.name}</Link>
                        )
                    }}
            />
            <Column field="dates.created" sortField="statements.created_at" sortable header="Created"
                    body={(statement) => displayDatetime(statement.created_at)}
            />
            <Column field="counts.items" header="Lots" />
            <Column field="total" sortField="statements.total" sortable header="Total"
                    body={(data) => currency(data, 'total')} />
            <Column field="payments" sortField="statements.payments" sortable header="Payments"
                    body={(data) => currency(data, 'payments')} />
            <Column field="balance" sortField="statements.balance" sortable header="Balance"
                    body={(data) => currency(data, 'balance')} />
            <Column headerStyle={{ width: '4rem' }} body={MenuBodyTemplate}></Column>
        </DataTable>
    )
}

export default StatementsTable