export const formatDate = (str) => {
    let formatted = str

    try {
        const date = new Date(str)

        // Ensure date format is compatible with HTML5 inputs
        formatted = date
            .toISOString()
            .split("T")[0];
    } catch (e) {

    }

    return formatted
}

export const formatDatetime = (str) => {
    let formatted = str

    try {
        // Refer to: https://stackoverflow.com/a/66558369
        const d = new Date(str)

        if (str.match('00.000000Z$')) {
            // Already in UTC
            formatted = d.toISOString().slice(0, -1)
        } else {
            formatted = (new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString()).slice(0, -1)
        }
    } catch (e) {

    }

    return formatted
}

export const formatTime = (str) => {
    let formatted = str

    try {
        const date = new Date(str)

        // Ensure date format is compatible with HTML5 inputs
        formatted = date
            .toISOString()
            .split("T")[1].replace("Z", "");
    } catch (e) {

    }

    return formatted
}

export const displayTime = (str, withSeconds = true) => {
    let formatted = str

    try {
        const d = new Date(str)

        formatted = d.toLocaleTimeString('en-us', {
            timeZoneName: 'short',
            hour: 'numeric',
            minute: '2-digit',
            second: withSeconds ? '2-digit' : undefined
        })
    } catch (e) {

    }

    return formatted
}

export const displayDate = (str, shortYear = true) => {
    let formatted = str

    try {
        const d = new Date(str)

        formatted = d.toLocaleDateString('en-us', {
            year: shortYear ? '2-digit' : 'numeric',
            month: 'numeric',
            day: 'numeric',
        })
    } catch (e) {

    }

    return formatted
}

export const displayDatetime = (str, withSeconds = true) => {
    if (! str) {
        return ''
    }

    return displayDate(str) + ' ' + displayTime(str, withSeconds)
}