import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { noSale } from 'services/invoices'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const NoSaleLotModal = ({ invoiceItem, visible, setVisible, then }) => {
    const notify = useNotificationsDispatch()
    const [ data, setData ] = useState({
        return_to_inventory: false
    })

    const onClick = () => {
        noSale(invoiceItem.invoice_id, invoiceItem.id, data, () => {
            setVisible(false)

            notify({
                message: 'Lot has been marked as No Sale.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(invoiceItem)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Mark as No Sale
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={onClick}>
                OK
            </Button>
        </div>
    )

    if (! invoiceItem) {
        return
    }

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible}
                header={header} footer={footer}
                style={{ width: '35rem' }}
                onHide={() => setVisible(false)}
        >
            <p>Are you sure you want to mark this lot as No Sale?</p>

            <p className="my-4">
                <b>Lot #{invoiceItem.lot.number}:</b> {invoiceItem.lot.name}
            </p>

            <div>
                <label className="flex gap-2 items-center">
                    <input type="checkbox"
                           checked={data.return_to_inventory || false}
                           onChange={(e) => {
                               setData({
                                   return_to_inventory: e.target.checked
                               })
                           }}
                    />
                    <span>Return To Inventory</span>
                </label>
            </div>
        </Dialog>
    )
}

export default NoSaleLotModal