import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import UserLayout from './components/UserLayout'
import NoteForm from 'components/notes/NoteForm'
import { get } from 'services/users'
import AssetGallery from 'components/assets/AssetGallery'
import { displayDatetime } from 'helpers/dates'

const UserNotes = () => {
    const entityType = 'App\\Models\\User'
    const { id } = useParams()
    const [ user, setUser ] = useState(null)

    useEffect(() => {
        loadUser()
    }, [id])

    const loadUser = () => {
        if (! id) {
            return
        }

        get(id, ({ data }) => {
            setUser(data.user)
        })
    }

    const afterSubmitNote = () => {
        loadUser()
    }

    if (! user) {
        return
    }

    return (
        <UserLayout id={id} user={user}>
            <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
                <div>
                    <NoteForm
                        note={{
                            notable_id: id,
                            notable_type: entityType,
                            options: {
                                type: 'primary'
                            }
                        }}
                        afterSubmit={afterSubmitNote}
                    />

                    {user.notes.map((n) => (
                        <div key={n.id}
                             className="rounded border border-gray-100 mt-4 p-2"
                        >
                            <div className="flex justify-between items-center">
                                <div className="font-bold">{n.author.first_name} {n.author.last_name}</div>
                                <div>{displayDatetime(n.created_at)}</div>
                            </div>

                            <NoteForm
                                note={n}
                                afterSubmit={afterSubmitNote}
                            />
                        </div>
                    ))}
                </div>
                <div>
                    <AssetGallery
                        entityId={id}
                        entityType={entityType}
                        assets={user.assets}
                    />
                </div>
            </div>
        </UserLayout>
    )
}

export default UserNotes