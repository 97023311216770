import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LotLayout from './components/LotLayout'
import NoteForm from 'components/notes/NoteForm'
import { get } from 'services/lots'
import { displayDatetime } from 'helpers/dates'

const LotNotes = () => {
    const entityType = 'App\\Models\\Lot'
    const { id } = useParams()
    const [ lot, setLot ] = useState(null)

    useEffect(() => {
        loadLot()
    }, [id])

    const loadLot = () => {
        if (! id) {
            return
        }

        get(id, ({ data }) => {
            setLot(data.lot)
        })
    }

    const afterSubmitNote = () => {
        loadLot()
    }

    if (! lot) {
        return
    }

    return (
        <LotLayout id={id} lot={lot} setLot={setLot}>
            <div className="mt-3">
                <NoteForm
                    note={{
                        notable_id: id,
                        notable_type: entityType,
                        options: {
                            type: 'primary'
                        }
                    }}
                    afterSubmit={afterSubmitNote}
                />

                {lot.notes.map((n) => (
                    <div key={n.id}
                         className="rounded border border-gray-100 mt-4 p-2"
                    >
                        <div className="flex justify-between items-center">
                            <div className="font-bold">{n.author.first_name} {n.author.last_name}</div>
                            <div>{displayDatetime(n.created_at)}</div>
                        </div>

                        <NoteForm
                            note={n}
                            afterSubmit={afterSubmitNote}
                        />
                    </div>
                ))}
            </div>
        </LotLayout>
    )
}

export default LotNotes