import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { FaArrowRotateLeft, FaArrowRotateRight } from 'react-icons/fa6';
import Button from 'components/buttons/Button';

const SortablePhoto = ({ asset, onDelete, onRotate, onApprove, approval }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: asset.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const rotate = (degrees) => {
    onRotate(asset, degrees);
  };

  return (
    <div
      ref={setNodeRef}
      className="relative"
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="absolute flex w-full justify-end">
        <div onClick={() => onDelete(asset)} className="p-2">
          <XCircleIcon className="h-5 w-5 text-black dark:text-white" />
        </div>
      </div>

      <img src={asset.url} alt="" className="mx-auto h-48 object-contain" />

      <div className="flex items-center justify-center gap-4 pt-2">
        <FaArrowRotateLeft className="h-5 w-5" onClick={() => rotate(90)} />

        {approval === 'admin' && (
          <Button size="sm" color="outline" onClick={() => onApprove(asset)}>
            Approve
          </Button>
        )}

        <FaArrowRotateRight className="h-5 w-5" onClick={() => rotate(-90)} />
      </div>
    </div>
  );
};

export default SortablePhoto;
