import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const SplitButton = ({ url, target, label, items, children, onClick }) => {
  return (
    <div className="inline-flex rounded-md shadow-sm">
      {url ? (
        <Link
          to={url}
          target={target}
          type="button"
          className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-brand-500 hover:bg-gray-50 focus:z-10"
        >
          {label || children}
        </Link>
      ) : (
        <div
          className="relative inline-flex cursor-pointer items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-brand-500 hover:bg-gray-50 focus:z-10 dark:bg-slate-700 dark:text-white"
          onClick={onClick}
        >
          {children}
        </div>
      )}
      <Menu as="div" className="relative -ml-px block">
        <Menu.Button className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-brand-500 hover:bg-gray-50 focus:z-10 dark:bg-slate-700 dark:text-white">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-700">
            <div className="py-1">
              {items
                .filter((item) => !!item)
                .map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <a
                        href={item.href || '#'}
                        target={item.target}
                        onClick={item.onClick}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900 dark:bg-slate-800 dark:text-gray-100'
                            : 'text-gray-700 dark:text-white',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        {item.name}
                      </a>
                    )}
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default SplitButton;
