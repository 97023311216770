import AuctionLayout from 'views/admin/auctions/components/AuctionLayout'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get, bidders as getBidders, updateRegistration } from 'services/auctions'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import AddBidderModal from './components/modals/AddBidderModal'
import EditRegistrationModal from './components/modals/EditRegistrationModal'
import RemoveFromAuctionModal from './components/modals/RemoveFromAuctionModal'
import RegistationActionPanel from './components/RegistrationActionPanel'
import Link from 'components/buttons/Link'
import { can, useAuth } from 'components/auth/AuthContext'
import {displayDatetime} from '../../../helpers/dates'

const Bidders = () => {
    const { id } = useParams()
    const [ auction, setAuction ] = useState(null)
    const [ bidders, setBidders ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const navigate = useNavigate()
    const [ activeRegistration, setActiveRegistration ] = useState(null)
    const [ visibleModal, setVisibleModal ] = useState(false)
    const auth = useAuth()
    const canEdit = can(auth, 'update_active_auctions')
    const approvalRequired = auction?.config?.approval_required

    useEffect(() => {
        setSort({
            by: searchParams.get('by') || 'bidder_name',
            dir: searchParams.get('dir') || -1
        })
    }, [searchParams])

    useEffect(() => {
        get(id, ({ data }) => {
            setAuction(data.auction)
        })
    }, [])

    useEffect(() => {
        loadBidders()
    }, [sort])

    const loadBidders = () => {
        getBidders(id, {
            by: sort.by,
            dir: sort.dir
        }, ({ data }) => {
            setBidders(data.bidders)
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    if (! auction) {
        return
    }

    const buttons = canEdit ? (
        <div className="ml-2 w-full flex justify-end gap-4 items-center">
            <AddBidderModal
                auction={auction}
                after={() => loadBidders()}
            />
        </div>
    ) : ''

    const afterUpdate = (registration) => {
        setBidders(bidders.map(b => {
            if (b.id === registration.id) {
                return registration
            }

            return b
        }))
    }

    const removeBidder = (registration) => {
        setBidders(
            bidders.filter(b => b.id !== registration.id)
        )
    }

    const edit = (registration) => {
        setActiveRegistration(registration)
        setVisibleModal('edit')
    }

    const remove = (registration) => {
        setActiveRegistration(registration)
        setVisibleModal('remove')
    }

    const userFieldTemplate = (user, value) => {
        return (
            <Link to={`/admin/user/${user?.id}/edit`}>
                {value}
            </Link>
        )
    }

    return (
        <AuctionLayout id={id} auction={auction} setAuction={setAuction} buttons={buttons}>
            {bidders.length > 0 && (
                <DataTable className="text-sm" value={bidders} lazy dataKey="id"
                           onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                           tableStyle={{ minWidth: '75rem' }}>
                    <Column field="bidder_name" header="Bidder Number" sortable />
                    <Column field="user.id"
                            body={(bidder) => userFieldTemplate(bidder.user, bidder.user?.id)}
                            header="User ID" sortable />
                    <Column field="user.first_name"
                            body={(bidder) => userFieldTemplate(bidder.user, bidder.user?.first_name)}
                            header="First Name" sortable />
                    <Column field="user.last_name"
                            body={(bidder) => userFieldTemplate(bidder.user, bidder.user?.last_name)}
                            header="Last Name" sortable />

                    {approvalRequired && (
                        <Column header="Status"
                                body={(registration) => {
                                    let status

                                    if (registration.approved_at) {
                                        status = 'Approved'
                                    } else if (registration.rejected_at) {
                                        status = 'Rejected'
                                    }

                                    if (status) {
                                        return (
                                            <>
                                                <span>{status}</span>
                                                {registration.reviewer && (
                                                    <>
                                                        <span className="mx-1">by</span>
                                                        <Link target="_blank" to={`/admin/user/${registration.reviewed_by}`}>
                                                            {registration.reviewer.name}
                                                        </Link>
                                                        <br />
                                                        <small>{displayDatetime(registration.approved_at || registration.rejected_at)}</small>
                                                    </>
                                                )}
                                            </>
                                        )
                                    }
                                }}
                        />
                    )}

                    <Column body={(registration) => {
                        return canEdit ? (
                            <RegistationActionPanel
                                handleStatusUpdate={afterUpdate}
                                edit={edit}
                                remove={remove}
                                registration={registration}
                                approvalRequired={approvalRequired}
                            />
                        ) : ''
                    }} style={{ width: '15%' }} />
                </DataTable>
            )}

            <EditRegistrationModal
                auction={auction}
                registration={activeRegistration}
                setRegistration={setActiveRegistration}
                visible={visibleModal}
                setVisible={setVisibleModal}
                then={(registration) => {
                    setActiveRegistration(null)
                    afterUpdate(registration)
                }}
            />

            <RemoveFromAuctionModal
                registration={activeRegistration}
                visible={visibleModal}
                setVisible={setVisibleModal}
                then={(registration) => {
                    setActiveRegistration(null)
                    removeBidder(registration)
                }}
            />
        </AuctionLayout>
    )
}

export default Bidders