import { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { bidders as getBidders } from 'services/auctions'
import { resell } from 'services/invoices'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import SelectField from 'components/fields/SelectField'
import Label from 'components/fields/Label'
import { InputNumber } from 'primereact/inputnumber'

const ResellLotModal = ({ auction, invoice, invoiceItem, visible, setVisible, then }) => {
    const notify = useNotificationsDispatch()

    const [ bidders, setBidders ] = useState([])
    const [ data, setData ] = useState({})

    useEffect(() => {
        if (invoiceItem) {
            setData({
                winner_id: invoice.buyer_id,
                bid: invoiceItem?.bid
            })
        }
    }, [invoiceItem])

    useEffect(() => {
        if (! auction) {
            return
        }

        getBidders(auction.id, {
            by: 'bidder_name'
        }, ({ data }) => {
            let bidders = data.bidders

            // append invoice user if not included (registration deleted, something else)
            if (invoice.buyer && bidders.filter(b => b.user_id === invoice.buyer_id).length === 0) {
                bidders = [
                    {
                        user: invoice.buyer
                    },
                    ...bidders
                ]
            }

            setBidders(bidders)
        })
    }, [auction])

    const setValue = (k, v) => {
        setData({
            ...data,
            [k]: v
        })
    }

    const onClick = () => {
        resell(invoiceItem.invoice_id, invoiceItem.id, data, () => {
            setVisible(false)

            notify({
                message: 'Lot has been resold successfully.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(invoiceItem)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Resell Lot
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={onClick}>
                Resell
            </Button>
        </div>
    )

    if (! invoiceItem) {
        return
    }

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible}
                header={header} footer={footer}
                style={{ width: '35rem' }}
                onHide={() => setVisible(false)}
        >
            <p>
                <b>Lot #{invoiceItem.lot.number}:</b> {invoiceItem.lot.name}
            </p>

            <p className="mt-4">
                Reselling this lot will sell it to a different bidder and/or for a different amount than it was
                originally sold.
            </p>

            <p className="mt-4">
                The lot's bid history will remain the same, but invoices and statements will be updated to reflect the
                correct bidder and winning amount.
            </p>

            <div className="flex flex-col gap-4 justify-center mt-6 mb-2">
                <SelectField
                    options={bidders?.map(bidder => {
                        return {
                            label: (bidder.bidder_name ? ('#' + bidder.bidder_name + ': ') : '')
                                + bidder.user?.name + ` (${bidder.user?.email})`,
                            value: bidder.user?.id
                        }
                    })}
                    value={data.winner_id || ''}
                    onChange={(e) => {
                        setValue('winner_id', e.target.value)
                    }}
                    label="Winning Bidder"
                />
            </div>
            <div className="mt-4">
                <Label htmlFor="resell_price" noMargin>
                    Price
                </Label>
                <div className="p-inputgroup flex-1 mt-2">
                    <span className="p-inputgroup-addon">$</span>
                    <InputNumber
                        inputId="resell_price"
                        maxFractionDigits={2}
                        value={data.bid || null}
                        onChange={(e) => {
                            setValue('bid', e.value)
                        }}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default ResellLotModal