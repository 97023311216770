import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getLotApprovals } from 'services/auctions';
import Link from 'components/buttons/Link'
import { displayDatetime } from 'helpers/dates'

const AuctionLotApprovalsIndex = () => {
    const [ lots, setLots ] = useState([])
    const [ expandedRows, setExpandedRows ] = useState([])
    const [ filters, setFilters ] = useState({})

    useEffect(() => {
        getLotApprovals(filters, ({ data }) => {
            setLots(data.lots)
        })
    }, [filters])

    const setFilter = (name, value) => {
        setFilters({
            ...filters,
            [name]: value ? 1 : 0
        })
    }

    const headerTemplate = (lot) => {
        return lot.auction && (
            <>
                <span className="vertical-align-middle ml-2 font-bold line-height-3">
                    <span>Auction: </span>
                    <Link to={`/admin/auction/${lot.auction_id}`}>{lot.auction.name}</Link>
                </span>
            </>
        );
    };

    const footerTemplate = (lot) => {
        const count = calculateLotCount(lot.auction)

        return (
            <>
                <td colSpan={6}>
                    <div className="flex justify-content-end font-bold w-full">Lots: {count}</div>
                </td>
            </>
        );
    };

    const calculateLotCount = (auction) => {
        let count = 0;

        if (! auction) {
            return count;
        }

        if (lots) {
            for (let lot of lots) {
                if (lot.auction_id === auction.id) {
                    count++;
                }
            }
        }

        return count;
    }

    return (
        <>
            <div>
                <div className="mt-4 mb-2 px-4 flex gap-4 items-center">
                    <label className="flex gap-2 items-center">
                        <input
                            type="checkbox"
                            value={filters.show_approved}
                            onChange={e => setFilter('show_approved', e.target.checked)}
                        />
                        <span>
                            Show Approved
                        </span>
                    </label>
                    <label className="flex gap-2 items-center">
                        <input
                            type="checkbox"
                            value={filters.show_rejected}
                            onChange={e => setFilter('show_rejected', e.target.checked)}
                        />
                        <span>
                            Show Rejected
                        </span>
                    </label>
                </div>

                <DataTable value={lots} rowGroupMode="subheader" groupRowsBy="auction.id"
                           sortMode="single" sortField="auction.name" sortOrder={1}
                           expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                           rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate}>
                    <Column body={(lot) => {
                        return (
                            <>
                                <Link to={`/admin/lot/${lot.id}/edit`}>
                                    #{lot.number}: {lot.name}
                                </Link>
                            </>
                        )
                    }} header="Lot" style={{ width: '25%' }} />
                    <Column field="dates.ends" header="Ends" style={{ width: '15%' }} body={(lot) => {
                        return lot.ends_at ? displayDatetime(lot.dates.local.ends_at) : ''
                    }} />
                    <Column header="Requested" style={{ width: '15%' }} body={(lot) => {
                        return displayDatetime(
                            lot.approvals.length > 0 ? lot.approvals[lot.approvals.length - 1].created_at : lot.created_at
                        )
                    }} />
                    <Column body={(lot) => {
                        const approval = lot.approvals.length > 0 ? lot.approvals[lot.approvals.length - 1] : false

                        return (
                            <span>
                                {approval ? (
                                    <>
                                        {approval.approved_at && 'Approved'}
                                        {approval.rejected_at && 'Rejected'}
                                        {approval.reviewer && (
                                            <>
                                                <span className="mx-1">by</span>
                                                <Link target="_blank" to={`/admin/user/${approval.reviewed_by}`}>
                                                    {approval.reviewer.name}
                                                </Link>
                                                <br />
                                                <small>{displayDatetime(approval.approved_at || approval.rejected_at)}</small>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {lot.approved_at && 'Approved'}
                                        {lot.rejected_at && 'Rejected'}
                                        {lot.reviewer && (
                                            <>
                                                <span className="mx-1">by</span>
                                                <Link target="_blank" to={`/admin/user/${lot.reviewed_by}`}>
                                                    {lot.reviewer.name}
                                                </Link>
                                                <br />
                                                <small>{displayDatetime(lot.approved_at || lot.rejected_at)}</small>
                                            </>
                                        )}
                                    </>
                                )}
                            </span>
                        )
                    }} header="Status" style={{ width: '15%' }} />
                </DataTable>
            </div>
        </>
    )
}

export default AuctionLotApprovalsIndex