import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import SortablePhoto from './SortablePhoto';
import axios from 'axios';
import UploadLimits from 'components/uploads/UploadLimits';

const AuctionPhotoGallery = ({ auction }) => {
  const [assets, setAssets] = useState(auction.assets);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (e) => {
    const { active, over } = e;

    if (active.id !== over.id) {
      setAssets((assets) => {
        const oldIndex = assets.findIndex((asset) => asset.id === active.id);
        const newIndex = assets.findIndex((asset) => asset.id === over.id);

        const resorted = arrayMove(assets, oldIndex, newIndex);

        axios.post(
          process.env.REACT_APP_API_URL +
            `/auction/${auction.id}/highlights/sort`,
          {
            order: resorted.map((asset) => asset.id),
          }
        );

        return resorted;
      });
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();

    acceptedFiles.forEach((file) => {
      formData.append('files[]', file);
    });

    axios
      .post(
        process.env.REACT_APP_API_URL +
          `/auction/${auction.id}/highlights/upload`,
        formData
      )
      .then((response) => {
        setAssets(response.data.auction.assets);
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
  });

  const onDelete = (asset) => {
    axios
      .delete(
        process.env.REACT_APP_API_URL +
          `/auction/${auction.id}/highlights/${asset.id}/delete`
      )
      .then((response) => {
        setAssets(response.data.auction.assets);
      });
  };

  if (!auction.editable) {
    return (
      <>
        <div className="mb-2 rounded-lg border border-gray-200 px-4 py-4">
          <div className="text-lg font-bold">{auction.name}</div>
          <div className="mt-2 rounded-lg bg-gray-200 px-4 py-4 dark:bg-navy-700">
            <div className="grid grid-cols-3 gap-4 md:grid-cols-6">
              {assets.map((asset) => (
                <img src={asset.url} alt="" className="h-48 object-cover" />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={assets}>
        <div className="mb-2 rounded-lg border border-gray-200 px-4 py-4">
          <div className="text-lg font-bold">{auction.name}</div>

          <div
            {...getRootProps()}
            className="mt-2 rounded-lg bg-gray-200 px-4 pb-4 dark:bg-navy-700"
          >
            <div onClick={open}>
              <input {...getInputProps()} />

              <p className="py-4 text-center">
                {isDragActive ? (
                  <>Drop the files here...</>
                ) : (
                  <>
                    Drag &amp; drop your files or{' '}
                    <span className="cursor-pointer underline">Browse</span>
                  </>
                )}
              </p>

              <UploadLimits />
            </div>

            <div className="grid grid-cols-3 gap-4 md:grid-cols-6">
              {assets.map((asset) => (
                <SortablePhoto
                  key={asset.id}
                  asset={asset}
                  onDelete={onDelete}
                />
              ))}
            </div>
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default AuctionPhotoGallery;
