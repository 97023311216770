import {useEffect, useRef, useState} from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { getCreditCards } from 'services/users'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import CreditCardSelectOrAdd from 'components/payments/CreditCardSelectOrAdd'
import PaymentTypeSelect from 'components/payments/PaymentTypeSelect'
import { FaPlus } from 'react-icons/fa6'
import Label from 'components/fields/Label'
import { InputNumber } from 'primereact/inputnumber'

const AddPaymentModal = ({ invoice, statement, payment, submit, visible, setVisible }) => {
    const defaultData = {
        payment_type_id: '',
        payment_type_name: '',
        amount: (statement ? statement.balance : invoice.balance) || 0.00
    }

    const notify = useNotificationsDispatch()
    const [ data, setData ] = useState(defaultData)
    const [ cards, setCards ] = useState([])
    const [ config, setConfig ] = useState({})
    const formRef = useRef()

    const loadCreditCards = () => {
        if (! invoice.buyer_id) {
            return
        }

        getCreditCards(invoice.buyer_id, ({ data }) => {
            setCards(data.cards)
            setConfig(data.config)
        })
    }

    useEffect(() => {
        if (invoice) {
            loadCreditCards()
        }
    }, [invoice])

    useEffect(() => {
        setData(payment || defaultData)
    }, [payment])

    const setValue = (k, v) => {
        setData({
            ...data,
            [k]: v
        })
    }

    const close = () => {
        setVisible(false)
        setData(defaultData)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (formRef.current) {
            formRef.current.requestSubmit()
        } else {
            doSubmit(data)
        }
    }

    const doSubmit = (data) => {
        submit(statement ? statement.id : invoice.id, data, ({ data }) => {
            close()

            notify({
                message: 'Payment has been added',
                title: 'Success!',
                type: 'success',
            })
        })
    }

    const header = (
        <div className="text-lg font-bold">
            {data.id ? 'Update Payment' : 'Add Payment'}
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => close()}>
                Cancel
            </Button>
            <Button onClick={handleSubmit}>
                {data.id ? 'Save' : 'Add'}
            </Button>
        </div>
    )

    const onSelectCard = (card) => {
        setData({
            ...data,
            card_id: card?.id
        })
    }

    const onCardSubmit = (result) => {
        doSubmit({
            ...data,
            card_id: result.setupIntent.payment_method
        })
    }

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible}
                header={header} footer={footer}
                onHide={() => close()}
                style={{ width: '25rem' }}
                contentClassName="overflow-visible"
        >
            <PaymentTypeSelect
                mode={statement ? 'statement' : 'invoice'}
                defaultTypeId={data.payment_type_id}
                onSelect={(id, name) => {
                    const newData = {...data}

                    newData.payment_type_id = id
                    newData.payment_type_name = name

                    if (name !== 'Credit Card') {
                        newData.card_id = null
                    }

                    setData(newData)
                }}
            />

            <div className="mt-4">
                <Label htmlFor="amount">
                    Amount
                </Label>
                <div className="p-inputgroup flex-1 mt-2">
                    <span className="p-inputgroup-addon">$</span>
                    <InputNumber
                        inputId="amount"
                        maxFractionDigits={2}
                        value={data.amount}
                        onChange={(e) => setValue('amount', e.value)}
                    />
                </div>
            </div>

            {invoice && data.payment_type_name === 'Credit Card' && (
                <div className="mt-4">
                    <CreditCardSelectOrAdd
                        user={invoice.buyer}
                        cards={cards}
                        config={config}
                        formRef={formRef}
                        onSelectCard={onSelectCard}
                        onCardSubmit={onCardSubmit}
                    />
                </div>
            )}
        </Dialog>
    )
}

export default AddPaymentModal