import { useEffect, useState } from 'react';
import { create } from 'services/items';
import { useNavigate } from 'react-router-dom';
import ItemForm from './components/ItemForm';
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext';

const CreateItem = () => {
  const navigate = useNavigate();
  const [item, setItem] = useState({
    qty: 1,
    key: 'no',
    runs: 'unknown',
    drives: 'unknown',
  });
  const [company, setCompany] = useState(null);
  const notify = useNotificationsDispatch();

  const submit = () => {
    create(item, ({ data }) => {
      navigate('/admin/item/' + data.item.id);

      notify({
        message: 'Inventory item created.',
        title: 'Success!',
        type: 'success',
      });
    });
  };

  return (
    <div>
      <ItemForm
        item={item}
        setItem={setItem}
        company={company}
        setCompany={setCompany}
        submit={submit}
      />
    </div>
  );
};

export default CreateItem;
