import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Information from './components/Information';
import Locations from './components/Locations';
import { get, create, update, all as getCompanies } from 'services/companies';
import { all as getClients } from 'services/clients';
import { getSalespersonList } from 'services/users';
import { CompanyTypes } from 'services/settings';
import CompanyLayout from './components/CompanyLayout';
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext';

const EditCompany = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [company, setCompany] = useState({
    locations: [],
  });
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [salespersonList, setSalespersonList] = useState([]);
  const [types, setTypes] = useState([]);
  const notify = useNotificationsDispatch();

  useEffect(() => {
    if (id) {
      get(id, ({ data }) => {
        setCompany(data.company);
      });
    }

    getClients({}, ({ data }) => {
      setClients(data.clients);

      if (!id && !company.client_id) {
        // Default to Peak client
        const peakClient = data.clients.filter(
          (c) => c.name === 'Peak Auctions Client'
        )[0];

        if (peakClient) {
          company.client_id = peakClient.id;
          setCompany({
            ...company,
          });
        }
      }
    });

    getCompanies({}, ({ data }) => {
      setCompanies(data.companies);
    });

    getSalespersonList({}, ({ data }) => {
      setSalespersonList(data.users.data);
    });

    CompanyTypes.index({}, ({ data }) => {
      setTypes(data.types);
    });
  }, []);

  const submit = (then, fail) => {
    if (id) {
      update(
        id,
        company,
        ({ data }) => {
          if (then) {
            then(data);
          }

          navigate('/admin/company/' + data.company.id);
          window.scroll(0, 0);
          notify({
            message: 'Company updated successfully.',
            title: 'Success!',
            type: 'success',
          });
        },
        fail
      );
    } else {
      create(
        company,
        ({ data }) => {
          if (then) {
            then(data);
          }

          navigate('/admin/company/' + data.company.id);
          window.scroll(0, 0);
          notify({
            message: 'Company created successfully.',
            title: 'Success!',
            type: 'success',
          });
        },
        fail
      );
    }
  };

  const setLocations = (locations) => {
    setCompany({
      ...company,
      locations,
    });
  };

  const content = (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        <Information
          company={company}
          setCompany={setCompany}
          clients={clients}
          companies={companies}
          salespersonList={salespersonList}
          types={types}
          submit={submit}
        />

        <Locations
          locations={company.locations}
          setLocations={setLocations}
          submit={submit}
        />
      </div>
    </div>
  );

  if (id) {
    return (
      <CompanyLayout id={id} company={company}>
        {content}
      </CompanyLayout>
    );
  }

  return <div>{content}</div>;
};

export default EditCompany;
