import React, { useState, useEffect } from 'react';
import InputField from 'components/fields/InputField'
import SelectField from 'components/fields/SelectField'

const ModalPremium = ({ show, onClose, fieldValues, onSave, setData, title = 'Override Premium', formType = 'Premium' }) => {
    const [values, setValues] = useState(fieldValues);

    const optionsPremium = [
        { value: 'percentage', label: 'Percentage' },
        { value: 'flat', label: 'Flat Fee' },
    ];

    useEffect(() => {
        setValues(fieldValues);
    }, [fieldValues]);

    if (!show) {
        return null;
    }

    const handleSave = () => {
        values.formType = formType;

        onSave(values);
        onClose();
    };

    const selectPremium = (v) => {
        setData('feeType', v)
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded shadow-lg w-full max-w-xl">
                <h2 className="text-xl mb-4">{title}</h2>
                

                <div className="grid grid-cols-1 gap-4">
                    <SelectField
                        extra="mb-3"
                        label="Type"
                        id="feeType"
                        value={values.feeType}
                        onChange={(e) => selectPremium(e.target.value)}
                        options={optionsPremium}
                    />

                    {(values.feeType === 'percentage') ? (
                        <InputField
                            extra="mb-3"
                            label="Rate"
                            id="rate"
                            value={values.rate || ''}
                            onChange={(e) => setData('rate', e.target.value)}
                            type="text"
                        />
                    ) : (
                        <InputField
                            extra="mb-3"
                            label="Amount"
                            id="amount"
                            value={values.amount || ''}
                            onChange={(e) => setData('amount', e.target.value)}
                            type="text"
                        />
                    )}

                </div>
                

                <div className="flex justify-end gap-4">
                    <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">Cancel</button>
                    <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Save</button>
                </div>
            </div>
        </div>
    );
};

export default ModalPremium;