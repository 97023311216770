import AuctionLayout from 'views/admin/auctions/components/AuctionLayout'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get, update, approveChanges, rejectChanges, listModified } from 'services/auctions'
import { BidIncrements, Premiums } from 'services/settings'
import { can, useAuth } from 'components/auth/AuthContext'
import Card from 'components/card'
import Button from 'components/buttons/Button'
import Label from 'components/fields/Label'
import AuctionForm from './components/AuctionForm'
import AdvancedEndTimeExtensionModal from './components/modals/AdvancedEndTimeExtensionModal'
import { TabView, TabPanel } from 'primereact/tabview'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CurrencyField from 'components/fields/CurrencyField'
import InputField from 'components/fields/InputField'
import { InputNumber } from 'primereact/inputnumber'
import SelectField from 'components/fields/SelectField'
import Blockquote from 'components/text/blockquote'
import Tooltip from 'components/tooltip/tooltip'
import ExtendedTooltip from 'components/tooltip/extended'
import { formatDate } from 'helpers/dates'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const Edit = () => {
    const auth = useAuth()
    const canUpdateUnrestricted = can(auth, 'update_auctions_unrestricted')
    const canCreateTemplates = can(auth, 'create_templates')
    const canApprove = can(auth, 'approve_auctions')

    const navigate = useNavigate()
    const notify = useNotificationsDispatch()
    const { id } = useParams()
    const [ auction, setAuction ] = useState(null)
    const [ company, setCompany ] = useState(null)
    const [ bidIncrements, setBidIncrements ] = useState([])
    const [ premiums, setPremiums ] = useState([])
    const [ modified, setModified ] = useState([])

    useEffect(() => {
        get(id, ({ data }) => {
            setAuction(data.auction)
            setCompany(data.auction.company)
        })

        BidIncrements.index({}, ({ data }) => {
            setBidIncrements(data.bidIncrements)
        })

        Premiums.index({}, ({ data }) => {
            setPremiums(data.premiums)
        })
    }, [id])

    useEffect(() => {
        if (! auction) {
            return
        }

        setModified(
            listModified(auction)
        )
    }, [auction])

    const setData = (k, v) => {
        setAuction({
            ...auction,
            [k]: v
        })
    }

    const setConfig = (k, v) => {
        const config = auction.config || {}

        setAuction({
            ...auction,
            config: {
                ...config,
                [k]: v
            }
        })
    }

    const toggleStagger = () => {
        setConfig('stagger_enabled', ! auction.config?.stagger_enabled)
    }

    const toggleCheckbox = (k) => {
        setConfig(k, ! (auction.config ? auction.config[k] : false))
    }

    const submit = (e, template = false) => {
        if (template) {
            auction.as_template = 1
            auction.is_template = 1
        }

        update(id, auction, ({ data }) => {
            if (template) {
                navigate(`/admin/auction/${data.auction.id}/edit`)

                window.scrollTo(0, 0)

                notify({
                    message: 'Template created.',
                    title: 'Success!',
                    type: 'success',
                })

                return
            }

            setAuction(data.auction)

            window.scrollTo(0, 0)

            notify({
                message: 'Auction changes submitted.',
                title: 'Success!',
                type: 'success',
            })
        })
    }

    const submitTemplate = (e) => {
        submit(e, true)
    }

    const handleApproveChanges = () => {
        approveChanges(id, ({ data }) => {
            setAuction(data.auction)

            notify({
                message: 'Auction changes approved & applied.',
                title: 'Success!',
                type: 'success',
            })
        });
    }

    const handleRejectChanges = () => {
        rejectChanges(id, ({ data }) => {
            setAuction(data.auction)

            notify({
                message: 'Auction changes rejected.',
                title: 'Success!',
                type: 'success',
            })
        });
    }

    if (! auction) {
        return
    }

    return (
        <AuctionLayout id={id} auction={auction} setAuction={setAuction}>
            <TabView>
                <TabPanel header="General">
                    {auction.pending_approvals.length > 0 && (
                        <Card extra={"w-1/2 mt-3 px-6 py-6"}>
                            {/* Header */}
                            <div className="w-full px-[8px] mb-4">
                                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                    Change Approval Required
                                </h4>
                                <p className="mt-1 text-base text-gray-600">
                                    This auction is active. Requested changes must be approved by an admin before going live.
                                </p>
                            </div>

                            <div>
                                <ul className="px-2">
                                    {modified.map((attr, i) => (
                                        <li key={'auction-' + i}>
                                            <span className="capitalize">{attr.k}</span>: {attr.v}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {canApprove && (
                                <div className="flex gap-4 items-center justify-end">
                                    <Button color="red-outline" onClick={handleRejectChanges}>
                                        Reject
                                    </Button>
                                    <Button onClick={handleApproveChanges}>
                                        Approve
                                    </Button>
                                </div>
                            )}
                        </Card>
                    )}

                    <Card extra={"w-1/2 mt-2 px-6 py-6"}>
                        {/* Header */}
                        <div className="w-full px-[8px] mb-4">
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                Auction Information
                            </h4>
                            <p className="mt-1 text-base text-gray-600">
                                Basic information related to the auction
                            </p>
                        </div>

                        <AuctionForm
                            auction={auction}
                            setAuction={setAuction}
                            company={company}
                            setCompany={setCompany}
                            setData={setData}
                        />
                    </Card>
                </TabPanel>
                {canUpdateUnrestricted && (
                    <TabPanel header="Details">
                        <div className="mb-8">
                            <Label>Intro</Label>

                            <ReactQuill theme="snow"
                                        className="mt-2"
                                        value={auction.config?.intro || ''}
                                        onChange={(v) => setConfig('intro', v)}
                            />
                        </div>
                        <div className="mb-8">
                            <Label>Preview</Label>

                            <ReactQuill theme="snow"
                                        className="mt-2"
                                        value={auction.config?.preview || ''}
                                        onChange={(v) => setConfig('preview', v)}
                            />
                        </div>
                        <div className="mb-8">
                            <Label>Terms</Label>

                            <ReactQuill theme="snow"
                                        className="mt-2"
                                        value={auction.config?.terms || ''}
                                        onChange={(v) => setConfig('terms', v)}
                            />
                        </div>
                    </TabPanel>
                )}
                {canUpdateUnrestricted && (
                    <TabPanel header="Lots">
                        <div className="flex gap-4 pb-4">
                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.sort_by_sale_order || false}
                                       onChange={() => toggleCheckbox('sort_by_sale_order')}
                                />
                                <span>Sort by Sale Order</span>
                            </label>
                        </div>
                        <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                            <InputField
                                extra="mb-3"
                                label="Stagger Qty."
                                id="stagger_qty"
                                value={auction.config?.stagger_qty || ''}
                                onChange={(e) => setConfig('stagger_qty', e.target.value)}
                                disabled={! auction.config?.stagger_enabled}
                                type="number"
                                above={(
                                    <div className="text-sm">
                                        <label className="flex gap-1 items-center">
                                            <input type="checkbox"
                                                   checked={auction.config?.stagger_enabled || false}
                                                   onChange={toggleStagger}
                                            />
                                            <span>Enable</span>
                                        </label>
                                    </div>
                                )}
                            />
                            <InputField
                                extra="mb-3"
                                label="Stagger Duration (minutes)"
                                id="stagger_duration"
                                value={auction.config?.stagger_duration || ''}
                                onChange={(e) => setConfig('stagger_duration', e.target.value)}
                                disabled={! auction.config?.stagger_enabled}
                                type="number"
                            />
                        </div>
                    </TabPanel>
                )}
                {canUpdateUnrestricted && (
                    <TabPanel header="Bidding">
                        <div className="flex gap-4 pb-4">
                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.approval_required || false}
                                       onChange={() => toggleCheckbox('approval_required')}
                                />
                                <span>Approval Required</span>
                            </label>

                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.hide_winning_info || false}
                                       onChange={() => toggleCheckbox('hide_winning_info')}
                                />
                                <span>Hide Winning Information</span>
                            </label>

                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.hide_bid_history || false}
                                       onChange={() => toggleCheckbox('hide_bid_history')}
                                />
                                <span>Hide Bid History</span>
                            </label>

                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.hide_winning_user || false}
                                       onChange={() => toggleCheckbox('hide_winning_user')}
                                />
                                <span>Hide Winning User</span>
                            </label>

                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.require_terms_approval || false}
                                       onChange={() => toggleCheckbox('require_terms_approval')}
                                />
                                <span>Require Terms Approval</span>
                            </label>

                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.require_license_number || false}
                                       onChange={() => toggleCheckbox('require_license_number')}
                                />
                                <span>Salvage / Dealer License Number Required</span>
                            </label>
                        </div>

                        <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                            <div>
                                <Label htmlFor="starting_bid">
                                    Starting Bid (for new lots)
                                </Label>
                                <div className="p-inputgroup flex-1 mt-2">
                                    <span className="p-inputgroup-addon">$</span>
                                    <InputNumber
                                        inputId="starting_bid"
                                        maxFractionDigits={2}
                                        value={auction.config?.starting_bid || ''}
                                        onChange={(e) => setConfig('starting_bid', e.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <SelectField
                                    id="bid_increment"
                                    label="Bid Increment (for new lots)"
                                    value={auction.config?.bid_increment || ''}
                                    onChange={(e) => setConfig('bid_increment', e.target.value)}
                                    options={bidIncrements.map(bidIncrement => {
                                        return {
                                            value: bidIncrement.id,
                                            label: bidIncrement.name
                                        }
                                    })}
                                    above={(
                                        <Tooltip>
                                            This will be applied to new lots when they are created.
                                            Changes here do not apply to existing lots. Existing lots must be updated individually or with Mass Update.
                                        </Tooltip>
                                    )}
                                />
                            </div>
                            <div>
                                <SelectField
                                    id="lock_bid_amount"
                                    label="Lock Bid Amount"
                                    value={auction.config?.lock_bid_amount || 'increment_abs'}
                                    onChange={(e) => setConfig('lock_bid_amount', e.target.value)}
                                    options={[
                                        {
                                            value: 'none',
                                            label: 'None'
                                        },
                                        {
                                            value: 'whole',
                                            label: 'Whole dollars'
                                        },
                                        {
                                            value: 'increment',
                                            label: 'Increment'
                                        },
                                        {
                                            value: 'increment_abs',
                                            label: 'Increment (absolute)'
                                        }
                                    ]}
                                    above={(
                                        <ExtendedTooltip>
                                            <p className="mb-2">
                                                This setting can be used to require a user's bid be "locked" to a certain multiple/increment
                                            </p>

                                            <p className="mb-2">
                                                <b>None:</b> any valid currency amount allowed
                                            </p>

                                            <p className="mb-2">
                                                <b>Whole dollars:</b> no pennies
                                            </p>

                                            <p className="mb-4">
                                                <b>Increment:</b> difference between bid amount and required bid must be a multiple of the increment at the required bid

                                                <Blockquote>
                                                    If required bid is $26 and increment is $5, the next required bid would be $31.
                                                </Blockquote>
                                            </p>
                                            <p>
                                                <b>Increment (absolute):</b> like increment, but not relative to required and looks at new amount for increment

                                                <Blockquote>
                                                    If the required bid is $26, current increment is $5 and the bidder wants to enter, $150, which is in a bid increment range of $25, the bid will be allowed as it is a multiple of $25.
                                                </Blockquote>
                                            </p>
                                        </ExtendedTooltip>
                                    )}
                                />
                            </div>
                            <div>
                                <InputField
                                    extra="mb-3"
                                    label="End Time Extension Threshold (seconds)"
                                    id="end_time_ext_threshold"
                                    value={auction.config?.end_time_ext_threshold || ''}
                                    onChange={(e) => setConfig('end_time_ext_threshold', e.target.value)}
                                    type="number"
                                    above={(
                                        <AdvancedEndTimeExtensionModal
                                            auction={auction}
                                            setConfig={setConfig}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <InputField
                                    extra="mb-3"
                                    label="End Time Extension Amount (seconds)"
                                    id="end_time_ext_amount"
                                    value={auction.config?.end_time_ext_amount || ''}
                                    onChange={(e) => setConfig('end_time_ext_amount', e.target.value)}
                                    type="number"
                                />
                            </div>
                            <div>
                                <SelectField
                                    id="end_time_ext_method"
                                    label="End Time Extension Method"
                                    value={auction.config?.end_time_ext_method || 'add'}
                                    onChange={(e) => setConfig('end_time_ext_method', e.target.value)}
                                    noDefault={true}
                                    options={[
                                        {
                                            value: '',
                                            label: ''
                                        },
                                        {
                                            value: 'add',
                                            label: 'Add'
                                        },
                                        {
                                            value: 'reset',
                                            label: 'Reset'
                                        }
                                    ]}
                                />
                            </div>
                            <div>
                                <Label htmlFor="auth_amount">
                                    Credit Card Authorization Amount
                                </Label>
                                <div className="p-inputgroup flex-1 mt-2">
                                    <span className="p-inputgroup-addon">$</span>
                                    <InputNumber
                                        inputId="auth_amount"
                                        maxFractionDigits={2}
                                        value={auction.config?.auth_amount || ''}
                                        onChange={(e) => setConfig('auth_amount', e.value)}
                                    />
                                </div>
                                <div className="text-sm ml-4 mt-2">
                                    <label className="flex gap-1 items-center">
                                        <input type="checkbox"
                                               checked={auction.config?.save_as_deposit || false}
                                               onChange={() => toggleCheckbox('save_as_deposit')}
                                        />
                                        <span>Save as Deposit</span>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <Label htmlFor="bidding_threshold">
                                    Bidding Threshold
                                </Label>
                                <div className="p-inputgroup flex-1 mt-2">
                                    <span className="p-inputgroup-addon">$</span>
                                    <InputNumber
                                        inputId="bidding_threshold"
                                        maxFractionDigits={2}
                                        value={auction.config?.bidding_threshold || null}
                                        onChange={(e) => setConfig('bidding_threshold', e.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                )}
                {canUpdateUnrestricted && (
                    <TabPanel header="Invoices">
                        <div className="flex gap-4 pb-4">
                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.hide_invoices || false}
                                       onChange={() => toggleCheckbox('hide_invoices')}
                                />
                                <span>Hide Invoices on Buyer Portal</span>
                            </label>

                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.disable_invoice_payments || false}
                                       onChange={() => toggleCheckbox('disable_invoice_payments')}
                                />
                                <span>Disable Invoice Payments on Buyer Portal</span>
                            </label>
                        </div>

                        <div className="grid grid-cols-1 gap-3 md:grid-cols-3 mb-8">
                            <div>
                                <SelectField
                                    id="buyers_premium"
                                    label="Buyer's Premium"
                                    value={auction.config?.premium || ''}
                                    onChange={(e) => setConfig('premium', e.target.value)}
                                    options={premiums.map(premium => {
                                        return {
                                            value: premium.id,
                                            label: premium.name
                                        }
                                    })}
                                />
                            </div>
                        </div>

                        <div className="mb-8">
                            <Label>Invoice Header</Label>

                            <ReactQuill theme="snow"
                                        className="mt-2"
                                        value={auction.config?.invoice_header || ''}
                                        onChange={(v) => setConfig('invoice_header', v)}
                            />
                        </div>

                        <div className="mb-8">
                            <Label>Invoice Footer</Label>

                            <ReactQuill theme="snow"
                                        className="mt-2"
                                        value={auction.config?.invoice_footer || ''}
                                        onChange={(v) => setConfig('invoice_footer', v)}
                            />
                        </div>
                    </TabPanel>
                )}
                {canUpdateUnrestricted && (
                    <TabPanel header="Statements">
                        <div className="flex gap-4 pb-4 mb-4">
                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.hide_statements || false}
                                       onChange={() => toggleCheckbox('hide_statements')}
                                />
                                <span>Hide Statements on Buyer Portal</span>
                            </label>
                        </div>

                        <div className="mb-8">
                            <Label>Statement Header</Label>

                            <ReactQuill theme="snow"
                                        className="mt-2"
                                        value={auction.config?.statement_header || ''}
                                        onChange={(v) => setConfig('statement_header', v)}
                            />
                        </div>

                        <div className="mb-8">
                            <Label>Statement Footer</Label>

                            <ReactQuill theme="snow"
                                        className="mt-2"
                                        value={auction.config?.statement_footer || ''}
                                        onChange={(v) => setConfig('statement_footer', v)}
                            />
                        </div>
                    </TabPanel>
                )}
                {canUpdateUnrestricted && (
                    <TabPanel header="Payments">
                        <div className="flex gap-4 pb-4">
                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.disable_invoice_payments || false}
                                       onChange={() => toggleCheckbox('disable_invoice_payments')}
                                />
                                <span>Disable Invoice Payments on Buyer Portal</span>
                            </label>
                        </div>

                        <div className="grid grid-cols-1 gap-3 md:grid-cols-6">
                            <InputField
                                extra="mb-3"
                                label="Invoice Due Date"
                                value={formatDate(auction.config?.invoice_due_date || '')}
                                onChange={(e) => setConfig('invoice_due_date', e.target.value)}
                                id="invoice_due_date"
                                type="date"
                            />
                            <CurrencyField
                                id="max_charge_value"
                                label="Maximum Charge Value"
                                value={auction.config?.max_charge_value || ''}
                                onChange={(e) => setConfig('max_charge_value', e.value)}
                            />
                        </div>
                    </TabPanel>
                )}
                {canUpdateUnrestricted && (
                    <TabPanel header="Location">
                        <div className="flex gap-4 pb-4">
                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.hide_lot_locations || false}
                                       onChange={() => toggleCheckbox('hide_lot_locations')}
                                />
                                <span>Hide Lot Locations on Buyer Portal</span>
                            </label>

                            <label className="flex gap-2 items-center">
                                <input type="checkbox"
                                       checked={auction.config?.hide_auction_location || false}
                                       onChange={() => toggleCheckbox('hide_auction_location')}
                                />
                                <span>Hide Auction Location on Buyer Portal</span>
                            </label>
                        </div>
                    </TabPanel>
                )}
            </TabView>

            <div className="flex gap-4 ml-4">
                <Button onClick={submit}>
                    {auction.editable ? 'Save Changes' : 'Request Changes'}
                </Button>

                {canCreateTemplates && (
                    <Button onClick={submitTemplate} color="outline">
                        Save as Template
                    </Button>
                )}
            </div>
        </AuctionLayout>
    )
}

export default Edit