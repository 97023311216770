import { useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import axios from 'axios';
import Label from './Label';

export default function AddressAutocomplete({
  inputId = 'address_autocomplete',
  label = 'Address',
  formData,
  setFormData,
}) {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (formData && formData.address) {
      setQuery(formData.address);
    }
  }, [formData]);

  const onAddressSelect = (address) => {
    if (address) {
      setFormData({
        ...formData,
        address: address.address,
        city: address.city,
        state: address.state_short,
        zip: address.zip,
      });
    }
  };

  // Fetch autocomplete suggestions
  const fetchSuggestions = async (query) => {
    if (query.length > 2) {
      // Start searching after 3 characters
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/google/address/autocomplete`,
          {
            params: { query },
          }
        );
        setSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching autocomplete suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  // Fetch address details when an address is selected
  const fetchAddressDetails = async (placeId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/google/address/place`,
        {
          params: { place_id: placeId },
        }
      );
      const addressDetails = response.data;

      onAddressSelect(addressDetails); // Pass selected address details to parent component if needed
    } catch (error) {
      console.error('Error fetching address details:', error);
    }
  };

  return (
    <div>
      {label && (
        <div className="flex gap-2">
          <Label htmlFor={inputId}>{label}</Label>
        </div>
      )}

      <Combobox as="div" value={query} className="relative mt-2 max-w-full">
        <Combobox.Input
          id={inputId}
          className="block w-full rounded-md border-0 bg-transparent px-3 py-3 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
          placeholder="Enter address..."
          onChange={(e) => {
            setQuery(e.target.value);
            fetchSuggestions(e.target.value);
          }}
        />

        {suggestions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 w-full rounded bg-white shadow-md dark:bg-slate-700">
            {suggestions.map((suggestion) => (
              <Combobox.Option
                key={suggestion.place_id}
                value={suggestion.address}
                className="cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-slate-800"
                onClick={() => fetchAddressDetails(suggestion.place_id)}
              >
                {suggestion.address}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  );
}
