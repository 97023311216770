import { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import InputField from 'components/fields/InputField'
import SelectField from 'components/fields/SelectField'
import Button from 'components/buttons/Button'
import { updateRegistration } from 'services/auctions'
import { getCreditCards } from 'services/users'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import { formatCurrency } from 'helpers/currency'
import { displayDatetime } from 'helpers/dates'
import {
    FaCcStripe
} from 'react-icons/fa6'
import AddDepositModal from './AddDepositModal'
import RemoveDepositModal from './RemoveDepositModal'
import { can, useAuth } from 'components/auth/AuthContext'

const EditRegistrationModal = ({ auction, registration, setRegistration, visible, setVisible, then }) => {
    const notify = useNotificationsDispatch()
    const [ user, setUser ] = useState(null)
    const [ cards, setCards ] = useState([])
    const [ config, setConfig ] = useState({})

    const auth = useAuth()
    const canAccessStripe = can(auth, 'access_stripe')

    const setData = (k, v) => {
        setRegistration({
            ...registration,
            [k]: v
        })
    }

    const afterDepositAdd = (data) => {
        setData('deposits', data.registration.deposits)
    }

    const afterDepositRemove = (deposit) => {
        setData('deposits', registration.deposits.map(d => {
            if (d.id === deposit.id) {
                return {
                    ...d,
                    deleted_at: true
                }
            }

            return d
        }))
    }

    const submit = () => {
        updateRegistration(registration.id, registration, ({ data }) => {
            setVisible(false)

            notify({
                message: 'Registration has been updated.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(data.registration)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Edit Auction Registration
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={submit}>
                Submit
            </Button>
        </div>
    )

    const statuses = [
        {
            value: 'pending',
            label: 'Pending',
        },
        {
            value: 'approved',
            label: 'Approved',
        },
        {
            value: 'rejected',
            label: 'Rejected',
        },
    ]

    const thresholdOptions = [
        {
            value: 'pending',
            label: 'Pending'
        },
        {
            value: 'approved',
            label: 'Approved'
        },
        {
            value: 'rejected',
            label: 'Rejected'
        },
    ]

    const loadCreditCards = () => {
        if (! registration.user_id) {
            return
        }

        getCreditCards(registration.user_id, ({ data }) => {
            setUser(data.user)
            setCards(data.cards)
            setConfig(data.config)
        })
    }

    useEffect(() => {
        if (registration) {
            loadCreditCards()
        }
    }, [registration])

    if (! registration) {
        return
    }

    if (! auction) {
        auction = registration.auction
    }

    let defaultDepositAmount = 0.00

    if (auction) {
        defaultDepositAmount = auction.config?.auth_amount || 0.00
    }

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible === 'edit'}
                header={header} footer={footer}
                onHide={() => setVisible(false)}
                style={{ width: '50rem' }}
        >
            <div className="grid grid-cols-2 gap-x-4 gap-y-6 items-start">
                <InputField
                    id="bidder_name"
                    label="Bidder Number"
                    value={registration.bidder_name || ''}
                    onChange={(e) => setData('bidder_name', e.target.value)}
                />

                <SelectField
                    id="status"
                    label="Status"
                    options={statuses}
                    value={registration.status || ''}
                    onChange={(e) => setData('status', e.target.value)}
                />

                <SelectField
                    id="threshold_approval"
                    label="Bidding Threshold Approval Status"
                    options={thresholdOptions}
                    placeholder="None"
                    value={registration.threshold_approval || ''}
                    onChange={(e) => setData('threshold_approval', e.target.value)}
                    extra="col-span-2"
                />

                <div>
                    <label className="flex gap-4 items-center">
                        <input type="checkbox"
                               checked={registration.deposit_paid || false}
                               onChange={(e) => setData('deposit_paid', e.target.checked)}
                        />
                        <span>Submitted Auth/Deposit</span>
                    </label>
                </div>
            </div>

            <div className="mt-6">
                <div className="flex items-center justify-between">
                    <h3 className="font-bold mb-2">Deposits</h3>

                    <AddDepositModal
                        registration={registration}
                        amount={defaultDepositAmount}
                        user={user}
                        cards={cards}
                        config={config}
                        then={afterDepositAdd}
                    />
                </div>

                {registration.deposits.length > 0 ? (
                    <table className="w-full text-center mt-4">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {registration.deposits.map(deposit => {
                                return (
                                    <tr key={deposit.id} className={deposit.deleted_at ? 'line-through' : ''}>
                                        <td className="py-2">{deposit.transaction.payment_type?.name || 'Credit Card'}</td>
                                        <td>{formatCurrency(deposit.transaction.amount)}</td>
                                        <td>{displayDatetime(deposit.transaction.created_at)}</td>
                                        <td className="capitalize">{deposit.transaction.status}</td>
                                        <td>
                                            {canAccessStripe && (
                                                <a href={deposit.transaction.stripe_url || '#'} target="_blank"
                                                   className={deposit.transaction.stripe_url ? '' : 'invisible'}>
                                                    <FaCcStripe className="text-3xl text-[#5433FF]" />
                                                </a>
                                            )}
                                        </td>
                                        <td>
                                            <RemoveDepositModal
                                                deposit={deposit}
                                                then={afterDepositRemove}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <em>No deposit has been submitted</em>
                )}
            </div>
        </Dialog>
    )
}

export default EditRegistrationModal