import Label from './Label'

const SelectField = ({
                         options,
                         label,
                         id,
                         name,
                         value,
                         extra = '',
                         className = '',
                         variant,
                         state,
                         disabled,
                         onChange,
                         placeholder = '',
                         above = '',
                         below = '',
                         size = null,
                         noDefault = false,
                         required = false,
                         multiple = false,
                         centered = false
                     }) => {

    return (
        <div className={`${extra}`}>
            {label && (
                <div className={`flex gap-2 ${centered ? 'justify-center' : ''}`}>
                    <Label
                        htmlFor={id}
                        variant={variant}
                        state={state}
                        noMargin
                    >{label}</Label>
                    {above}
                </div>
            )}
            <select
                disabled={disabled}
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                multiple={multiple}
                size={size}
                className={`${label && 'mt-2'} flex ${multiple ? '' : 'h-12'} w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${className} ${
                    disabled === true
                        ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                        : state === "error"
                            ? "border-red-500 focus:ring-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                            : state === "success"
                                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                                : "border-gray-200 dark:!border-white/10 dark:text-white"
                }`}
            >
                {! (noDefault || multiple) && (
                    <option value="">{placeholder}</option>
                )}

                {options?.map((option, i) => (
                    <option key={i} value={option.value ?? i}>{option.label ?? (typeof option === 'string' ? option : 'N/A')}</option>
                ))}
            </select>
            {below}
        </div>
    )
}

export default SelectField
