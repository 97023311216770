import {
    FaBan,
    FaCheck,
    FaPencil,
    FaTrashCan
} from 'react-icons/fa6'
import Button from 'components/buttons/Button'
import { updateRegistration } from 'services/auctions'

const RegistrationActionPanel = ({ approvalRequired, registration, handleStatusUpdate, edit, remove }) => {
    const approve = (registration) => {
        updateRegistration(registration.id, {
            approve: true
        }, ({ data }) => {
            handleStatusUpdate(data.registration, 'approved')
        })
    }

    const reject = (registration) => {
        updateRegistration(registration.id, {
            reject: true
        }, ({ data }) => {
            handleStatusUpdate(data.registration, 'rejected')
        })
    }

    return (
        <div className="flex gap-2 items-center">
            {approvalRequired && (
                <>
                    <span
                        className={"text-green-600 text-xl cursor-pointer px-2 " + (registration.status !== 'pending' ? 'invisible' : '')}
                        title="Approve"
                        onClick={() => approve(registration)}
                    >
                        <FaCheck />
                    </span>
                    <span
                        className={"text-red-600 text-xl cursor-pointer px-3 " + (registration.status !== 'pending' ? 'invisible' : '')}
                        title="Reject"
                        onClick={() => reject(registration)}
                    >
                        <FaBan />
                    </span>
                </>
            )}
            <Button
                color="outline"
                title="Edit"
                onClick={() => edit(registration)}
            >
                <FaPencil />
            </Button>
            <Button color="red"
                    title="Delete"
                    onClick={() => remove(registration)}
            >
                <FaTrashCan />
            </Button>
        </div>
    )
}

export default RegistrationActionPanel