import React, { useState, useEffect } from 'react';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import { index } from 'services/expensesgroups';

const ModalExpenses = ({ show, onClose, fieldValues, onSave, setData, statement, type = 'Expense' }) => {
  const [values, setValues] = useState(fieldValues);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    setValues(fieldValues);
  }, [fieldValues]);

  // set expenses groups to groups
  useEffect(() => {
    index({}, ({ data }) => {
      setGroups(data.expenses_groups);
    });
  }, []);

  if (!show) {
    return null;
  }

  const handleSave = () => {
    // add type to values
    values.formType = 'Expenses';

    onSave(values);
    onClose();
  };

  const relation = statement ? 'Statement' : 'Invoice'

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-4xl rounded bg-white p-6 shadow-lg dark:bg-navy-700">
        <h2 className="mb-4 text-xl">
          {
            values.id
                ? (values.item_id ? 'Update Lot Expense' : `Update ${relation} ${type}`)
                : (values.item_id ? 'New Lot Expense' : `New ${relation} ${type}`)
          }
        </h2>

        <SelectField
          extra="mb-3"
          label="Group"
          id="expenses_group_id"
          value={values.expense_group_id || ''}
          onChange={(e) => setData('expense_group_id', e.target.value)}
          options={groups.map((group) => {
            return {
              value: group.id,
              label: group.list_name,
            };
          })}
        />

        <InputField
          extra="mb-3"
          label="Description"
          id="expense_description"
          value={values.description || ''}
          onChange={(e) => setData('description', e.target.value)}
          type="text"
        />

        <SelectField
          extra="mb-3"
          label="Type"
          id="expense_type"
          value={values.type || ''}
          onChange={(e) => setData('type', e.target.value)}
          options={[
            {
              value: 'flat',
              label: 'Flat',
            },
            {
              value: 'rate',
              label: 'Rate',
            },
          ]}
        />

        <InputField
          extra="mb-3"
          label="Amount"
          id="expenses"
          value={(type === 'Credit' ? Math.abs(values.amount) : values.amount) || ''}
          onChange={(e) => setData('amount', e.target.value)}
          type="text"
        />

        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="rounded bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalExpenses;
