import { useEffect, useState } from 'react'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import axios from 'axios'

const useEcho = () => {
    const [echoInstance, setEchoInstance] = useState(null);

    useEffect(() => {
        //  Setup Pusher client
        const PusherClient = new Pusher(process.env.REACT_APP_REVERB_APP_KEY, {
            wsHost: process.env.REACT_APP_REVERB_HOST,
            wsPort: process.env.REACT_APP_REVERB_PORT ?? 80,
            wssPort: process.env.REACT_APP_REVERB_PORT ?? 443,
            forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? "https") === "https",
            enabledTransports: ["ws", "wss"],
            disableStats: true,
            cluster: "mt1",
            authorizer: (channel, options) => {
                return {
                    authorize: async (socketId, callback) => {
                        axios.post(process.env.REACT_APP_API_URL + '/broadcasting/auth', {
                            socket_id: socketId,
                            channel_name: channel.name,
                        })
                            .then((response) => {
                                console.log('broadcasting auth', response.data);
                                callback(null, response.data);
                            })
                            .catch((error) => {
                                callback(error);
                            });
                    },
                };
            },
        });

        // Create Echo instance
        const echo = new Echo({
            broadcaster: "reverb",
            client: PusherClient,
        });

        setEchoInstance(echo);

        // Cleanup on unmount
        return () => {
            if (echo) {
                echo.disconnect();
            }
        };
    }, []);

    return echoInstance;
};

export default useEcho;